$cart__margin         : $spacer--large !default;
$cart__border         : 1px solid $gray-lighter !default;

$cart__bottom-margin  : 0 !default;
$cart__bottom-padding : $spacer 0 0 0 !default;

// Checkout messages
$checkout-message__background: #fff3cd;
$checkout-message__color: #856404;
$checkout-message-error__background: #f8d7da;
$checkout-message-error__color: #721c24;

.cart {
    display: flex;
    margin-bottom: $cart__margin;

    &__bottom {
        display: flex;
        margin: $cart__bottom-margin;
        padding: $cart__bottom-padding;
        border-top: $cart__border;
    }

    &__products-list {
        margin: 0;
        border-top: $cart__border;
    }
}

.checkout-message {
    width: 100%;
    display: block;
    padding: 10px 20px;
    background: $checkout-message__background;
    margin-bottom: 15px;
    color: $checkout-message__color;

    p {
        color: $checkout-message__color;
    }

    &.customer-message {
        background: $checkout-message-error__background;
        color: $checkout-message-error__color;

        p {
            color: $checkout-message-error__color;
        }
    }
}
