$radio__size              : $spacer--medium !default;
$radio__dot-size          : 6px !default;
$radio__label-font-size   : $font-size-small !default;
$radio__label-color       : $black !default;
$radio__label-color-active: $color-primary !default;
$radio__icon-border       : 1px solid $black !default;
$radio__icon-background   : $color-primary !default;
$radio__icon-transition   : $transition-base !default;

.radio {
    position: relative;
    margin-bottom: $spacer--medium;

    &__fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    &__legend {
        padding-bottom: $spacer--medium;
    }

    &__handler {
        position: relative;
    }

    &__field {
        position: absolute;
        left: 0;
        height: $radio__size;
        width: $radio__size;
        opacity: 0;
        z-index: -1;

        &:checked + .radio__label {
            color: $radio__label-color-active;
            .radio__icon {
                &:before {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &:focus + .radio__label {
            @include focus();
        }
    }

    &__label {
        position: relative;
        height: $radio__size;
        width: 100%;
        vertical-align: top;
        line-height: $radio__size;
        cursor: pointer;
        font-size: $radio__label-font-size;
        color: $radio__label-color;
    }

    &__icon {
        position: relative;
        display: inline-block;
        border: $radio__icon-border;
        border-radius: $radio__size;
        width: $radio__size;
        height: $radio__size;
        margin-right: $spacer;
        vertical-align: top;

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            width: $radio__dot-size;
            height: $radio__dot-size;
            border-radius: $radio__dot-size;
            margin: auto;
            opacity: 0;
            transform: scale(0);
            background-color: $radio__icon-background;
            transition: $radio__icon-transition;
        }
    }
}
