$mega-menu__margin: 0 !default;
$mega-menu__font-color: $white !default;
$mega-menu__font-weight: $font-weight-normal !default;
$mega-menu__font-weight--bold: $font-weight-bold !default;
$mega-menu__font-size: $font-size-small !default;
$mega-menu__font-size--small: $design-font-size-small !default;
$mega-menu__background-color: $black !default;

$mega-menu__list-padding: 0 $spacer !default;
$mega-menu__list-justify-content: flex-start !default;
$mega-menu__list-background-color: $black !default;

$mega-menu__inner-list-padding: 0 !default;
$mega-menu__inner-list-background-color: $white !default;
$mega-menu__inner-list-box-shadow--level1: 0 4px 6px 0 rgba(51, 51, 51, 0.3) !default;
$mega-menu__inner-list-border--level1: 1px solid $gray-light !default;

$mega-menu__inner-item-padding--parent: $spacer--large 0 $spacer--large
    ($spacer--large * 2) !default;
$mega-menu__inner-item-padding--parent-child: 0 !default;
$mega-menu__inner-item-maargin--parent-child: 0 0 $spacer--medium !default;
$mega-menu__inner-item--count: 4;

$mega-menu__inner-item-padding--image: 0 0 0 112px !default;

$mega-menu__link-padding: $spacer $spacer !default;
$mega-menu__link-padding--small: $spacer;
$mega-menu__link-margin: 0 $spacer--medium 0 0 !default;
$mega-menu__link-margin--small: 0 $spacer 0 0 !default;
$mega-menu__link-color: $white !default;
$mega-menu__link-color--active: $color-primary !default;
$mega-menu__link-text-decoration: none !default;
$mega-menu__link-text-transform: none;

$mega-menu__inner-link-margin: 0 !default;
$mega-menu__inner-link-padding: 0 !default;
$mega-menu__inner-link-font-weight: $font-weight-normal !default;
$mega-menu__inner-link-text-decoration: none !default;
$mega-menu__inner-link-color: $black !default;
$mega-menu__inner-link-color--active: $color-primary !default;
$mega-menu__inner-link-color--image-focus: $font-color-base !default;

$mega-menu__link-current-background-color: $gray;
$mega-menu__link-border-radius: $border-radius;
$mega-menu__menu-height: 62px;

$mega-menu__menu-max-width: calc(#{$max-content-width} - (2 * $spacer));

.mega-menu {
    display: none;
    background: $mega-menu__background-color;
    color: $mega-menu__font-color;
    position: relative;
    margin: $mega-menu__margin;
    z-index: -1;
    @include mq($screen-l) {
        display: block;
    }

    &__list {
        height: $mega-menu__menu-height;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: $mega-menu__list-justify-content;
        list-style: none;
        background-color: $mega-menu__list-background-color;
    }

    &__inner-list {
        max-width: $mega-menu__menu-max-width;
        margin-left: 0;
        padding: 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        background-color: $mega-menu__inner-list-background-color;
        list-style: none;

        &--level1 {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 4;
            width: 100%;
            padding: 40px 40px 10px 40px;
            border-top: $mega-menu__inner-list-border--level1;
            box-shadow: $mega-menu__inner-list-box-shadow--level1;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        flex-grow: 1;

        &:last-child {
            flex-grow: 0;
        }

        &--parent {
            &:hover,
            &:focus {
                .mega-menu__inner-list--level1 {
                    display: flex;
                }
            }

            // support for keyboard focus, for different browsers
            & > .mega-menu__inner-list--level1:focus {
                display: flex;
            }

            &:focus-within {
                .mega-menu__inner-list--level1 {
                    display: flex;
                }
            }

            & > .mega-menu__link:focus + .mega-menu__inner-list--level1 {
                display: flex;
            }
        }
    }

    &__inner-item {
        padding: 0;
        margin: 0;

        &--level1 {
            flex-basis: calc(100% / #{$mega-menu__inner-item--count});
            margin-bottom: 30px;
        }

        &--parent {
            //padding: $mega-menu__inner-item-padding--parent;
            & > .mega-menu__inner-link {
                display: inline-block;
                padding: $mega-menu__inner-item-padding--parent-child;
                font-size: $mega-menu__font-size;
                font-weight: $mega-menu__font-weight--bold;
            }
        }

        &--image {
            display: flex;
            flex-basis: 100%;
            justify-content: flex-end;
            padding: $mega-menu__inner-item-padding--image;

            .mega-menu__inner-link {
                display: block;
                &:focus {
                    @include focus();
                    background-color: transparent;
                    color: $mega-menu__inner-link-color--image-focus;
                }
            }

            img {
                width: auto;
            }
        }
    }

    &__link {
        display: block;
        margin: $mega-menu__link-margin--small;
        padding: $mega-menu__link-padding;
        font-size: $mega-menu__font-size;
        font-weight: $mega-menu__font-weight;
        color: $mega-menu__link-color;
        text-decoration: $mega-menu__link-text-decoration;
        text-transform: $mega-menu__link-text-transform;
        border-radius: $mega-menu__link-border-radius;
        &:focus,
        &:hover {
            text-decoration: $mega-menu__link-text-decoration;
            color: $mega-menu__link-color--active;
        }
        &.current {
            background: $mega-menu__link-current-background-color;
        }

        @include mq($screen-m) {
            padding: $mega-menu__link-padding--small;
            margin: 0;
        }

        @include mq($screen-xl) {
            padding: $mega-menu__link-padding;
        }

        @include fluid-font(
            $screen-m,
            $screen-xl,
            $mega-menu__font-size--small,
            $mega-menu__font-size
        );
    }

    &__inner-link {
        margin: $mega-menu__inner-link-margin;
        padding: $mega-menu__inner-link-padding;
        white-space: nowrap;
        font-size: $mega-menu__font-size;
        font-weight: $mega-menu__inner-link-font-weight;
        color: $mega-menu__inner-link-color;
        text-decoration: $mega-menu__inner-link-text-decoration;

        &:hover, &:focus {
            text-decoration: $mega-menu__inner-link-text-decoration;
            color: $mega-menu__inner-link-color--active;
        }
    }

    &__column-hidden {
        > .mega-menu__inner-link {
            display: none;
        }
    }
}
