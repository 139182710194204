$pager__list-padding                        : $spacer !default;
$pager__item-margin                         : 0 $spacer !default;
$pager__item-after-height--current          : $spacer !default;
$pager__item-after-background-color--current: $color-primary !default;
$pager__item-after-transition--current      : $transition-base  !default;
$pager__item-size                           : $spacer--extra-large !default;
$pager__item--current                       : $font-color-base !default;
$pager__item-text-decoration--current       : none !default;
$pager__icon-width                          : $spacer--medium !default;
$pager__icon-height                         : $spacer--medium !default;
$pager__link-fill-disabled                  : $gray !default;
$pager__link-fill                           : $color-primary !default;

$pager__item-bg-color: $gray-light;
$pager__item-border-color: darken($pager__item-bg-color, 5%);
$pager__font-color: $font-color-base;

.pager {
    width: 100%;
    text-align: center;
    margin-top: $spacer--large;

    &__list {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        border-radius: $border-radius;
        box-shadow: $design-box-shadow;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: $pager__item-size;
        height: $pager__item-size;
        width: $pager__item-size;
        padding: 0;
        background: $pager__item-bg-color;
        border-right: 1px solid $pager__item-border-color;
        color: $pager__font-color;
        font-weight: bold;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
            border-right: none;
        }

        &--current {
            color: $pager__item--current;
            position: relative;
            text-decoration: $pager__item-text-decoration--current;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $pager__item-size;
        text-decoration: none;
        color: inherit;

        &[disabled] {
            fill: $pager__link-fill-disabled;
            pointer-events: none;
            cursor: default;
        }

        &--prev,
        &--next {
            fill: $pager__link-fill;
        }
    }

    &__icon {
        width: $pager__icon-width;
        height: $pager__icon-height;
    }
}
