.am-fromto-widget {
    display: flex !important;
    margin: $spacer--large 0 $spacer--medium 0;

    .am-filter-price,
    .am-filter-go {
        flex: 1;
    }

    .am-filter-price {
        width: 20px;
    }

    .am-filter-go {
        margin-right: 0 !important;
        height: 32px;
    }

    .delimiter {
        margin: 0 $spacer--small;
        line-height: 32px;
    }
}
