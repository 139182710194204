.amasty-extrafee {
    &-options {
        .title {
            margin-bottom: $spacer;
        }
    }

    &-option {
        .radio {
            margin-bottom: 0;
        }
        .label {
            display: inline-block;
            margin-left: $spacer--medium;
        }
    }

    &.totals {
        display: flex;
        justify-content: space-between;
    }
}

.amasty-extrafee-opc-block-fees {
    .title {
        margin-bottom: $spacer;
    }
}

.checkout-summary {
    .amexfee-block-content {
        & > .amexfee-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .amexfee-title-icon {
                display: none;
                height: 18px;
                width: 18px;
            }
        }

        &:not(.active) {
            & > .amexfee-title {
                .amexfee-title-icon.-open {
                    display: block;
                }
            }
        }

        &.active {
            & > .amexfee-title {
                .amexfee-title-icon.-collapsed {
                    display: block;
                }
            }
        }

        .amexfee-fieldset {
            padding: 10px 0 0 0;

            .amexfee-title {
                margin: 0;
                color: $gray-lighter;
            }
        }



        // &-option {
        //     .label {
        //         margin-left: $spacer--large !important;
        //     }
        // }

        // &-opc-block-fees {
        //     margin-top: $spacer--medium;
        //     margin-bottom: $spacer--large;
        // }
    }
}
