$logo__max-width: 84px !default;
$logo__max-width\@medium: 112px !default;
$logo__image-height: 50px !default;
$logo__image-height\@medium: 100px !default;

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    // max-width: $logo__max-width;

    @include mq($screen-m + 1px) {
        flex: 1 $logo__max-width\@medium;
        // max-width: $logo__max-width\@medium;
    }

    &__image {
        height: $logo__image-height;

        @include mq($screen-m + 1px) {
            height: $logo__image-height\@medium;
        }
    }
}
