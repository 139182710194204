$top-bar__margin              : 8px 0 !default;
$top-bar__divider-color       : $gray-dark !default;
$top-bar__divider-height      : 14px !default;
$top-bar__background-color    : $gray-light !default;
$top-bar__height              : 30px !default;
$top-bar__font-size           : $font-size-small !default;
$top-bar__icon-size           : 14px !default;
$top-bar__link-padding        : 0 16px !default;
$top-bar__link-text-decoration: none !default;

.top-bar {
    display: none;
    background-color: $top-bar__background-color;
    @include mq($screen-xl) {
        display: block;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        height: $top-bar__height;
    }

    &__contact-info {
        margin: $top-bar__margin;
        font-size: $top-bar__font-size;

        &-link {
            display: inline-flex;
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid $top-bar__divider-color;
            line-height: $top-bar__divider-height;
            text-decoration: $top-bar__link-text-decoration;

            &:focus,
            &:hover {
                text-decoration: $top-bar__link-text-decoration;
            }
        }
    }

    &__links {
        margin: $top-bar__margin;
        font-size: $top-bar__font-size;

        &-item {
            padding: $top-bar__link-padding;
            border-left: 1px solid $top-bar__divider-color;
            line-height: $top-bar__divider-height;
            text-decoration: $top-bar__link-text-decoration;

            &:focus,
            &:hover {
                text-decoration: $top-bar__link-text-decoration;
            }

            &:first-child {
                border-left: none;
            }
        }

        &-icon {
            width: $top-bar__icon-size ;
            height: $top-bar__icon-size ;
            margin-right: 8px;
            vertical-align: top;
        }
    }
}
