@import url("//fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");

@font-face {
    font-family: "TradeGothic";
    src: url("../fonts/tradegothic-regular.woff2") format("woff2"),
        url("../fonts/tradegothic-regular.woff") format("woff"),
        url("../fonts/tradegothic-regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "TradeGothic";
    src: url("../fonts/tradegothic-bold.woff2") format("woff2"),
        url("../fonts/tradegothic-bold.woff") format("woff"),
        url("../fonts/tradegothic-bold.svg") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
