$counter__height: 40px;
$counter__width: 150px;
$counter__action-bg: $gray-dark;
$counter__input-border: 1px solid $gray-light;
$counter__box-shadow: $design-box-shadow;
$counter__overlay-bg: rgba(0, 0, 0, 0.25);


// to-do: use theme's "quantity-update"
.counter-xx {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $counter__height;
    width: $counter__width;
    box-shadow: $counter__box-shadow;
    border-radius: $border-radius;

    &.-disabled {
        pointer-events: none;

        &:after {
            content: " ";
            z-index: 10;
            display: block;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            background: $counter__overlay-bg;
            border-radius: $border-radius;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(#{$counter__width} / 3);
        height: inherit;
        margin: 0;
        background: $counter__action-bg;
        line-height: $counter__height;
        text-decoration: none;
        text-align: center;
        color: $white;

        &:hover {
            color: $red;
        }

        &:first-child {
            border-radius: $border-radius 0 0 $border-radius;
        }
        &:last-child {
            border-radius: 0 $border-radius $border-radius 0;
        }
    }

    &__input {
        width: calc(#{$counter__width} / 3);
        height: inherit;
        margin: 0;
        padding: 0;
        background: $white;
        text-align: center;
        border: $counter__input-border;
        border-left: none;
        border-right: none;
    }
}
