$dashboard-table-margin: $spacer--medium !default;

.dashboard-table {
    overflow: hidden;

    &--tab {
        padding: 10px;
        margin-bottom: $dashboard-table-margin;
        border: 1px solid $gray;

        .dashboard-table__content {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin-bottom: $dashboard-table-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__content {
        margin-bottom: $dashboard-table-margin;
        overflow-x: auto;
    }

    &__table {
        margin: 0;
    }

    &__link {
        display: inline-flex;
        &:focus {
            @include focus-inline();
        }
    }

    &__spacer {
        margin-right: $spacer;
    }

    &__title {
        margin-bottom: 0;
        margin-right: $dashboard-table-margin;
    }
}
