$cart-list-item__padding                   : $spacer--medium 0 !default;
$cart-list-item__border                    : 1px solid $gray-light !default;

$cart-list-item__image-width               : 160px !default;
$cart-list-item__image-padding             : 0 $spacer--medium 0 0 !default;

$cart-list-item__name-weight               : $font-weight-bold !default;
$cart-list-item__name-width                : 65% !default;
$cart-list-item__name-padding\@small       : 0 $spacer--medium 0 0 !default;

$cart-list-item__details-width             : calc(100% - #{$cart-list-item__name-width}) !default;
$cart-list-item__details-margin            : $spacer--medium 0 !default;
$cart-list-item__details-margin\@small     : 0 !default;

$cart-list-item__attributes-margin         : 0 0 $spacer--medium 0 !default;

$cart-list-item__price-margin              : $spacer 0 0 0 !default;
$cart-list-item__price-margin\@medium      : 0 !default;

$cart-list-item__qty-width                 : 100% !default;
$cart-list-item__qty-width\@small          : auto !default;

$cart-list-item__qty-wrapper-margin        : 0 !default;

$cart-list-item__qty-input-width\@small    : 70px !default;

$cart-list-item__actions-item-margin       : 0 0 0 $spacer !default;

.cart-list-item {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    flex-direction: column;
    padding: $cart-list-item__padding;
    border-bottom: $cart-list-item__border;

    @include mq($screen-s) {
        flex-direction: row;
    }

    &__col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        &:not(.-img) {
            padding: 0 $spacer--medium;
        }

        &.-img {
            display: none;

            @include mq($screen-m) {
                display: flex;
                flex: 0 0 10%;
            }
        }

        &.-info {
            span {
                display: block;

                &[class^="price-"] {
                    display: inline-block;
                }
            }
        }

        &.-qty {
            display: inline;
            margin-top: $spacer--medium;

            @include mq($screen-m) {
                flex: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: unset;
            }

            .quantity-update {
                display: inline-flex;
            }
        }

        &.-total {
            flex: 0 0 15%;
            display: flex;
            margin-top: $spacer--medium;

            @include mq($screen-s) {
                align-items: flex-end;
            }

            @include mq($screen-m) {
                margin-top: unset;
                align-items: flex-end;
            }
        }
    }

    &__name {
        display: block;
        font-weight: $cart-list-item__name-weight;
    }

    &__remove {
        margin-top: $spacer;
    }
}
