$link__color              : $blue !default;
$link__decoration         : underline !default;
$link__font-family        : $font-family-base !default;
$link__font-weight        : $font-weight-normal !default;
$link__color-hover        : $color-secondary !default;
$link__line-height        : $font-line-height !default;
$link__transition         : $transition-base !default;
$link__text-decoration    : none !default;
$link__color--invert      : $gray-darker !default;
$link__color-hover--invert: $blue !default;

.link {
    color: $link__color;
    text-decoration: $link__decoration;
    transition: $link__transition;
    font-family: $link__font-family;
    font-weight: $link__font-weight;
    line-height: $link__line-height;
    text-decoration: $link__text-decoration;

    &:hover {
        color: $link__color-hover;
    }

    &:focus {
        @include focus();
    }

    &--invert {
        color: $link__color--invert;

        &:hover {
            color: $link__color-hover--invert;
        }
    }

    &--secondary {
        color: $gray-lighter;

        &:hover {
            color: $color-primary;
        }
    }

    // visible only for screen readers and when focused
    &--visually-hidden {
        @include visually-hidden($usePseudo: true);
    }

    p &,
    cite & {
        background-clip: content-box;
        &:focus:not(.banner) {
            @include focus-inline();
        }
    }
}

a {
    @extend .link;
}

p a {
    &:focus:not(.banner) {
        color: #447fd0;
        background-color: unset;
    }
}
