$amcheckout__title-bg: #f5f5f5;
$amcheckout__icon-border: 2px solid $red;
$amcheckout__item-message-width: 300px;

$amcheckout__method-padding: 0 $spacer;

.amcheckout {
    &-items {
        .message {
            &.error {
                width: $amcheckout__item-message-width;
            }
        }

        .amcheckout-method {
            padding: $amcheckout__method-padding;

            .col {
                border-top: none;
            }

            input {
                margin-bottom: 0;
            }
        }
    }

    &-step-container {

        .amcheckout-title {
            background: $amcheckout__title-bg !important;

            & > .amcheckout-icon {
                border: none !important;

                &:before {
                    border-right: $amcheckout__icon-border !important;
                    border-bottom: $amcheckout__icon-border !important;
                }
            }
        }
        .amcheckout-shipping-methods {
            .amcheckout-method {
                margin: 0;
                &.-selected {
                    border-color: $red !important;
                }
            }
        }
        .checkout-payment-method {
            .amcheckout-payment-methods {
                .payment-method {
                    &._active {
                        border-color: $red !important;
                    }
                }
            }

            &.submit {
                .payment-methods {
                    .actions-toolbar {
                        .button {
                            background: $red !important;
                            border-radius: $border-radius !important;
                        }
                    }
                }
            }
        }
    }
}
