@mixin flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.flag-icon {
    @include flag-icon-background;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    &:before {
        content: "\00a0";
    }
}

@mixin flag-icon($country) {
    .flag-icon[class*="flag-icon-#{$country}"] {
        background-image: url("#{$flag-icon-css-path}/#{$country}.svg");
    }
}
