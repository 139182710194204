$list__style                       : none !default;
$list__margin                      : 0 !default;
$list__padding                     : 0 !default;
$list__item-padding                : 5px 0 !default;
$list__content-padding             : 10px !default;
$list__label-padding               : 0 5px 5px 0 !default;

// Horizontal
$list__padding--horizontal         : 10px !default;

// Horizontal\@medium
$list__padding--horizontal\@medium : 10px !default;

// With icon
$list__padding--with-icon          : 12px !default;

// With image
$list__width--image                : 16px !default;

// Divided
$list__border--divider             : 1px solid $gray-dark !default;

$list__item-padding--native        : 0 0 $spacer--medium $spacer--large !default;

.list {
    margin: $list__margin;
    padding: $list__padding;
    list-style: $list__style;

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--horizontal {
        display: flex;
        flex-flow: row wrap;
        .list__item {
            padding: $list__padding--horizontal;

            &:first-child {
                padding-top: $list__padding--horizontal;
            }
            &:last-child {
                padding-bottom: $list__padding--horizontal;
            }
        }
    }

    &--horizontal\@medium {
        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;

            .list__item {
                padding: $list__padding--horizontal\@medium;

                &:first-child {
                    padding-top: $list__padding--horizontal\@medium;
                }
                &:last-child {
                    padding-bottom: $list__padding--horizontal\@medium;
                }
            }
        }
    }

    &--with-icon {
        justify-content: center;

        .list__item {
            display: flex;
            align-items: center;
            padding: 0;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .list__icon-link {
            padding: $list__padding--with-icon;
        }
    }

    &--with-image {
        .list__item {
            display: flex;
        }
        .list__image {
            width: $list__width--image;
            height: auto;
        }
    }

    &--divided {
        .list__item {
            border-bottom: $list__border--divider;
            &:last-child {
                border-bottom: none;
            }
        }

        &.list--horizontal {
            .list__item {
                border-bottom: none;
                border-right: $list__border--divider;
                &:last-child {
                    border-right: none;
                }
            }
        }

        &.list--horizontal\@medium {
            .list__item {
                @include mq($screen-m) {
                    border-bottom: none;
                    border-right: $list__border--divider;
                }
                &:last-child {
                    @include mq($screen-m) {
                        border-right: none;
                    }
                }
            }
        }
    }

    &--description {
        display: inline-block;
    }

    &--native {
        .list__item {
            position: relative;
            padding: $list__item-padding--native;

            &:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 12px;
                width: $spacer;
                height: $spacer;
                background-color: $color-primary;
                border-radius: 4px;
            }
        }
    }

    &__item {
        display: list-item;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__label {
        clear: left;
        float: left;
        padding: $list__label-padding;
        &:after {
            content: ': ';
        }
    }

    &__value {
        float: left;
        padding: $list__label-padding;
    }

    &__content {
        padding-left: $list__content-padding;
    }
}
