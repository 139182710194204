$price__spacing: $spacer !default;
$price__spacing--special: $spacer--small !default;
$price__color--special: $red !default;
$price__color--discount: $red !default;
$price__font-weight: $font-weight-normal !default;
$price__font-weight--old: $font-weight-normal !default;
$price__font-weight--special: $font-weight-bold !default;
$price__border--discount: 2px solid $red;

$price__border--discount: 2px solid $red;

.price {
    display: flex;
    flex-wrap: wrap;

    .price-box {
        position: relative;
    }

    &__value {
        font-weight: $price__font-weight;

        &--old {
            font-weight: $price__font-weight--old;
            margin-right: $price__spacing;

            .price-container span,
            .price {
                text-decoration: line-through;
            }
        }

        &--special {
            color: $price__color--special;
            font-weight: $price__font-weight--special;
            margin-right: $price__spacing--special;

            & > ins {
                text-decoration: none;
            }
        }

        &--between {
            display: block;

            &:last-child {
                margin-top: $price__spacing;
            }
        }
    }

    &__discount {
        padding: 0 $spacer;
        color: $price__color--special;
        border: $price__border--discount;
        border-radius: $border-radius;
    }

    &__text {
        font-weight: $price__font-weight--old;
    }

    &-container {
        display: inline-block;
    }
}
