$size-before: 26px;
$mgz-product-item__padding--small: $spacer;
$mgz-product-text__margin: 0 0 $spacer--large 0;

.magezon-builder {
    .mgz-container {
        width: 1328px;
    }
}
.mgz-element {
    &-column {
        .mgz-element-inner {
            max-width: 100%;

            @include mq($screen-m) {
                max-width: auto;
            }
        }
    }
    &-heading {
        &.prefix {
            &-arrow {
                .mgz-element-heading-text {
                    &:before {
                        content: url("../images/icons/Arrow.svg");
                        display: inline-block;
                        width: $size-before;
                        height: $size-before;
                    }
                }
            }
        }
        &-text {
            line-height: 1.2;
            &:not(h3) {
                margin: $mgz-product-text__margin !important;
            }
        }
    }
    .item {
        &.product {
            &.product-item {
                padding: $mgz-product-item__padding--small;
                p {
                    line-height: inherit;
                }
            }
        }
    }
}
