.search {
    &-autocomplete {
        max-width: 100vw;
        box-shadow: $design-box-shadow;
    }
}

.header__row {
    .form .search-autocomplete {
        max-height: calc(100vh - 90px);
        margin: 0;
        top: unset;
    }
}
