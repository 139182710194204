$owl-dot__size: 16px;
$owl-dot__margin: 8px;
$owl-dot__color: white;
$owl-dot__color--active: red;

.mgz-carousel {
    .owl-dots {
        .owl-dot {
            span {
                width: $owl-dot__size !important;
                height: $owl-dot__size !important;
            }

            &:hover {
                span {
                    margin: $owl-dot__margin !important;
                }
            }

            &.active {
                span {
                    position: relative;
                    margin: $owl-dot__margin !important;

                    &:after {
                        content: "";
                        position: absolute;
                        left: $owl-dot__size / 4;
                        top: $owl-dot__size / 4;
                        width: $owl-dot__size / 2;
                        height: $owl-dot__size / 2;
                        background-color: red;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .owl-item {
        img {
            margin-bottom: $spacer--medium;
        }

        .product-item-name {
            display: inline-block;
            margin-bottom: $spacer;
        }
    }
}
