$header-button__size: 36px !default;
$header-button__margin: 0 0 0 $spacer !default;
$header-button__margin--extra-large: 0 0 0 $spacer--medium !default;
$header-button__padding: 0 !default;

$header-button__icon-margin: 0 0 2px 0 !default;
$header-button__icon-margin--medium: 0 0 4px 0 !default;
$header-button__icon-padding: 0 0 4px 0 !default;
$header-button__icon-color: lighten($gray, 25%) !default;
$header-button__icon-color--hover: $color-primary !default;
$header-button__transition: $transition-base !default;

$header-button__text-font-family: $font-family-base !default;
$header-button__text-color: $gray !default;
$header-button__text-font-size: 10px !default;
$header-button__text-line-height: 10px !default;

$header-button__counter-top: 0 !default;
$header-button__counter-top--medium: 0 !default;
$header-button__counter-right: -8px !default;
$header-button__counter-font-size: $font-size-extra-small !default;
$header-button__counter-background: transparent !default;
$header-button__counter-color: $design-grey-2 !default;
$header-button__counter-size: 14px !default;
$header-button__counter-border: 2px solid $white !default;

$header-button__counter-dot-size: 2px !default;
$header-button__counter-dot-bg-color: $white !default;

// Additional variables
$header-button__background-color: $gray-light;
$header-button__counter-font-weight: 600;

.header-button {
    position: relative;
    height: $header-button__size;
    min-width: $header-button__size;
    display: flex;
    padding: $header-button__padding;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-family: $header-button__text-font-family;
    background: transparent;

    @include mq($screen-xl) {
        margin: $header-button__margin--extra-large;
    }

    @include mq($screen-l) {
        margin: $header-button__margin;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        .header-button__icon {
            fill: $header-button__icon-color--hover;
        }
    }

    &--reset-margin {
        margin: 0;
    }

    &__icon {
        fill: $header-button__icon-color;
        transition: $header-button__transition;
        @include mq($screen-m) {
            // margin: $header-button__icon-margin--medium;
        }
    }

    &__text {
        font-size: $header-button__text-font-size;
        line-height: $header-button__text-line-height;
        text-align: center;
        color: $header-button__text-color;
    }

    &__counter {
        position: absolute;
        right: -4px;
        // margin-left: $spacer;
        font-weight: $header-button__counter-font-weight;

        &--hidden {
            display: none;
        }
    }
}
