$tab__border-width                    : 1px !default;
$tab__border-width--large             : 8px !default;

// title
$tab__title-padding                   : $spacer--medium 0 !default;
$tab__title-color                     : $gray !default;
$tab__title-color--active             : $design-red !default;
$tab__title-font-weight--large-active : $font-weight-bold !default;
$tab__title-font-weight               : $font-weight-bold !default;
$tab__title-font-size                 : $font-size-extra-large;
$tab__title-background                : $bg-color-base !default;
$tab__title-border                    : 2px solid $gray-light;
$tab__title-font                      : $font-family-alt;
$tab__title-text-margin               : $spacer--medium;


// content
$tab__content-padding                 : $spacer--medium !default;
$tab__content-padding--large          : $spacer--large 0 !default;
$tab__content-border                  : $border-width-base $border-style-base $border-color-base;

// icon
$tab__icon-color                      : $gray !default;
$tab__icon-color--active              : $gray-darker !default;
$tab__icon-padding                    : 16px !default;
$tab__icon-size                       : 48px !default;

.tab {
    @include mq($screen-l) {
        display: flex;
        flex-flow: row wrap;
    }

    &__title {
        position: relative;
        display: block;
        padding: $tab__title-padding;
        background: $tab__title-background;
        text-align: initial;
        font-weight: $tab__title-font-weight;
        text-transform: uppercase;
        font-size: $tab__title-font-size;
        color: $tab__title-color;
        cursor: pointer;
        border-bottom: $tab__title-border;
        font-family: $tab__title-font;

        &-text {
            margin-left: $tab__title-text-margin;
            @include mq($screen-l) {
                margin-left: unset;
            }
        }

        @include mq($screen-l) {
            order: -1;
            border-top: none;
            margin-right: $spacer--large;
            border-bottom: none;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .tab__icon {
            fill: $tab__icon-color;
            pointer-events: none;
        }

        &--active {
            border-top: none;
            color: $tab__title-color--active;

            @include mq($screen-l) {
                font-weight: $tab__title-font-weight--large-active;
                z-index: 2;
            }

            .tab__icon {
                transform: rotateX(180deg);
                fill: $tab__icon-color--active;
            }
        }

        .counter {
            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }
    }

    &__icon {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: $tab__icon-size;
        height: $tab__icon-size;
        padding: $tab__icon-padding;

        @include mq($screen-l) {
            display: none;
        }
    }

    &__content {
        position: relative;
        display: none;
        width: 100%;
        padding: $tab__content-padding;
        z-index: -1;
        opacity: 0;
        overflow: hidden;

        @include mq($screen-l) {
            padding: $tab__content-padding--large;
            flex-basis: 100%;
            z-index: 1;
            border-top: $tab__content-border;
        }

        &--active {
            display: block;
            opacity: 1;
            z-index: 1;
        }
    }
}
