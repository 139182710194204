$mgz__width-button: 300px;

.mgz-button {
    // .featured-product & {
    //     display: none !important;
    // }
    max-width: 100%;

    .mgz-btn {
        @extend .button;

        width: $mgz__width-button;
    }
}

.featured-product {
    .mgz-button {
        padding: 0 !important;

        @extend .button;
        @extend .button--inverse;

        .mgz-button {
            @extend .button;
            @extend .button--inverse;
        }
    }
}
