// Content below is copied from modules' _module.less
// https://community.magento.com/t5/Magento-2-x-Less-to-Sass/Custom-modules-web-css-source-modules-less-not-compiled-with/td-p/45669

.mppromobar-style {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
}

#mppromobar-fixed-page-top {
    position: sticky;
    top: 0;
    z-index: 10;
}

#mppromobar-fixed-page-bottom {
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.mppromobar-style .content-template {
    padding: 1rem 2rem;
}

.mppromobar-style .content-template a {
    text-decoration: none;
}

.mppromobar-style .content-template .mppromobar_url_text {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
}

.mppromobar-style .content-template .mppromobar-close-btn {
    font-size: 20px;
    float: right;
    cursor: pointer;
}

.mppromobar-style .content-template .mppromobar-close-btn:hover {
    font-weight: bold;
}
