$compare__border                   : 1px solid $gray-lighter !default;
$compare__margin-bottom            : $spacer--medium !default;
$compare__table-width              : 100% !default;
$compare__label-width              : 120px !default;
$compare__label-background         : $gray-lightest !default;
$compare__label-padding            : $spacer !default;
$compare__label-padding\@medium    : $spacer--medium !default;
$compare__attributes-margin-bottom : $spacer !default;
$compare__cell-padding             : $spacer--medium !default;
$compare__cell-min-width           : 160px !default;
$compare__cell-list-padding-left   : $spacer--medium !default;
$compare__cell-item-line-height    : $font-line-height !default;
$compare__image-size               : auto !default;
$compare__image-max-size           : 100% !default;
$compare__form-margin              : 0 !default;
$compare__cart-width               : 120px !default;
$compare__cart-padding             : 0 !default;
$compare__cart-padding\@medium     : 0 $spacer--medium !default;
$compare__wishlist-size            : 48px !default;
$compare__wishlist-padding         : 0 !default;
$compare__wishlist-background      : transparent !default;
$compare__wishlist-border          : none !default;
$compare__wishlist-cursor          : pointer !default;
$compare__wishlist-icon-size       : 18px !default;
$compare__wishlist-icon-fill       : $color-secondary !default;
$compare__wishlist-icon-fill--hover: $color-primary !default;
$compare__wishlist-icon-transition : $transition-base !default;

.compare {
    margin-bottom: $compare__margin-bottom;
    overflow-x: auto;

    &__table {
        border-collapse: collapse;
        border-spacing: 0;
        border: $compare__border;
        @include mq($screen-m) {
            width: $compare__table-width;
            table-layout: fixed;
        }
    }

    &__label {
        width: $compare__label-width;
        padding: $compare__label-padding;
        background-color: $compare__label-background;
        border-bottom: $compare__border;
        @include mq($screen-m) {
            padding: $compare__label-padding\@medium;
        }
    }

    &__cell {
        position: relative;
        padding: $compare__cell-padding;
        min-width: $compare__cell-min-width;
        border-left: $compare__border;
        border-bottom: $compare__border;
        ul {
            padding-left: $compare__cell-list-padding-left;
        }
        li {
            line-height: $compare__cell-item-line-height;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image {
        width: $compare__image-size;
        height: $compare__image-size;
        max-width: $compare__image-max-size;
        max-height: $compare__image-max-size;
    }

    .price {
        font-weight: $font-weight-bold;
    }

    &__link,
    &__rating,
    .price {
        display: block;
        margin-bottom: $compare__attributes-margin-bottom;
    }

    &__buttons-container {
        display: flex;
        justify-content: center;
        @include mq($screen-m) {
            flex-wrap: wrap;
        }
    }

    &__secondary-buttons {
        display: flex;
    }

    &__form {
        margin: $compare__form-margin;
    }

    &__cart {
        padding: $compare__cart-padding;
        @include mq($screen-m) {
            width: $compare__cart-width;
            padding: $compare__cart-padding\@medium;
        }
    }

    &__cart-text {
        display: none;
        @include mq($screen-m) {
            display: inline;
        }
    }

    &__cart-icon {
        @include mq($screen-m) {
            display: none;
        }
    }

    &__wishlist,
    &__remove {
        height: $compare__wishlist-size;
        width: $compare__wishlist-size;
        padding: $compare__wishlist-padding;
        background: $compare__wishlist-background;
        border: $compare__wishlist-border;
        cursor: $compare__wishlist-cursor;

        &:hover,
        &:focus {
            background: $compare__wishlist-background;
            .compare__wishlist-icon,
            .compare__remove-icon {
                fill: $compare__wishlist-icon-fill--hover;
            }
        }
    }

    &__wishlist-icon,
    &__remove-icon {
        fill: $compare__wishlist-icon-fill;
        width: $compare__wishlist-icon-size;
        height: $compare__wishlist-icon-size;
        transition: $compare__wishlist-icon-transition;
    }
}
