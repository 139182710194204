$headings-margin: 0 0 $spacer--medium 0 !default;
$headings-margin--page: 0 !default;

$headings-font-family: $font-family-heading !default;
$headings-font-weight: 700 !default;
$headings-line-height: $font-line-height !default;
$headings-color: $color-secondary !default;
$heading-color--page: $design-red !default;

$headings-text-transform: none !default;
$headings-text-transform--page: uppercase !default;

$heading-font-size--page: 24px !default;
$heading-font-size--first-level: 48px !default;
$heading-font-size--first-level-alt: 36px !default;
$heading-font-size--second-level: 48px !default;
$heading-font-size--third-level: 24px !default;
$heading-font-size--fourth-level: 24px !default;
$heading-font-size--fifth-level: 21px !default;
$heading-font-size--sixth-level: 21px !default;

// $heading-font-size--page: 24px !default;
// $heading-font-size--first-level: 18px !default;
// $heading-font-size--second-level: 18px !default;
// $heading-font-size--third-level: 14px !default;
// $heading-font-size--fourth-level: 14px !default;
// $heading-font-size--fifth-level: 12px !default;
// $heading-font-size--sixth-level: 12px !default;

.heading {
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {
       font-size: $heading-font-size--first-level-alt;

        @include mq($screen-s) {
            font-size: $heading-font-size--first-level;
        }
    }

    &--second-level {
        font-size: $heading-font-size--second-level;
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
    }

    &--page {
        line-height: 1.2;
        color: $heading-color--page;
        margin-bottom: $headings-margin--page;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}

