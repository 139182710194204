$product-view__action-elements-margin-top\@small: 28px !default;
$product-view__action-elements-margin-top\@large: 0 !default;

$product-view__action-icon-color: $gray-darker !default;
$product-view__action-icon-color-hover: $color-primary !default;
$product-view__action-icon-transition: $transition-base !default;
$product-view__action-icon-compare-padding: 16px 16px !default;
$product-view__action-icon-compare-width: 16px !default;
$product-view__action-icon-compare-height: 16px !default;
$product-view__action-icon-emailfriend-padding: 16px 14px !default;
$product-view__action-icon-emailfriend-height: 16px !default;
$product-view__action-icon-emailfriend-width: 20px !default;
$product-view__action-icon-wishlist-padding: 15px 14px !default;
$product-view__action-icon-wishlist-height: 18px !default;
$product-view__action-icon-wishlist-width: 20px !default;
$product-view__action-item-size: 48px !default;
$product-view__action-item-spacing: $spacer !default;

$product-view__add-review-link-font-size: $font-size-base !default;
$product-view__add-review-link-font-size\@medium: $font-size-small !default;

$product-view__add-to-cart-margin-right: $spacer--medium !default;

$product-view__base-elements-vertical-spacing: $spacer !default;

$product-view__title-font-size: $font-size-xxl !default;
$product-view__title-color: $black;

$product-view__col-base-spacing: $spacer--medium !default;
$product-view__col-base-reduce-width: $product-view__col-base-spacing / 2 !default;
$product-view__col-left-base-width: 50% !default;
$product-view__col-left-width: calc(
    #{$product-view__col-left-base-width} - #{$product-view__col-base-reduce-width}
) !default;
$product-view__col-right-base-width: 100% - #{$product-view__col-left-base-width} !default;
$product-view__col-right-width: calc(
    #{$product-view__col-right-base-width} - #{$product-view__col-base-reduce-width}
) !default;

$product-view__description-color: $gray-darker !default;
$product-view__description-margin: 0 $spacer $spacer !default;
$product-view__description-margin\@medium: 0 0 $spacer !default;
$product-view__description-padding: $spacer--medium !default;
$product-view__description-padding\@medium: 0 0 $spacer--medium 0 !default;
$product-view__description-border-top: 1px solid $gray-light !default;
$product-view__description-border-bottom: 1px solid $gray-light !default;
$product-view__description-line-height: 1.7 !default;
$product-view__description-title-font-weight: $font-weight-bold !default;
$product-view__description-bundle-margin: 0 !default;
$product-view__description-bundle-padding: $spacer--medium 0 !default;

$product-view__customization-space: $spacer--medium !default;
$product-view__customization-border: 1px solid $gray-light !default;

$product-view__summary-padding: $spacer--medium 0 !default;
$product-view__summary-border: 1px solid $gray-light !default;
$product-view__summary-margin: 0 0 $spacer--large !default;
$product-view__summary-title-font-weight: $font-weight-bold !default;
$product-view__summary-item-space: $spacer--medium !default;
$product-view__summary-price-margin-bottom: $spacer--medium !default;
$product-view__summary-price-font-size: $font-size-large !default;
$product-view__summary-price-font-weight: $font-weight-bold !default;
$product-view__summary-unit-padding-left: 4px !default;
$product-view__summary-unit-font-size: $font-size-base !default;

$product-view__extra-actions-margin-bottom: $spacer--medium !default;
$product-view__extra-actions-margin-bottom\@medium: $spacer !default;
$product-view__extra-actions-margin-bottom\@large: 0 !default;
$product-view__extra-actions-padding: 0 $spacer !default;
$product-view__extra-actions-margin: $spacer--medium 0 $spacer--medium 0;
$product-view__extra-actions-margin-large: 0 $spacer--medium $spacer--medium $spacer--medium;

$product-view__add-to-cart-margin: 10px 0;
$product-view__extra-actions-margin-bottom\@medium: 0 !default;
$product-view__add-to-cart-margin\@large: 20px 0;
$product-view__extra-actions-margin-bottom\@large: 0 !default;
$product-view__add-to-cart-btn-min-width: 150px !default;
$product-view__add-to-cart-btn-padding\@large: 0 $spacer--large !default;

$product-view__update-wishlist-btn-width: auto !default;

$product-view__gallery-bottom-border: none !default;
$product-view__gallery-bottom-border\@medium: none !default;
$product-view__gallery-margin-bottom: $spacer--medium !default;
$product-view__gallery-margin-bottom\@medium: 0 !default;
$product-view__gallery-padding-bottom: $spacer !default;
$product-view__gallery-padding-bottom\@medium: 0 !default;

$product-view__information-padding: 0 20px !default;
$product-view__information-padding\@medium: 0 !default;

$product-view__main-actions-margin-bottom: 23px !default;

$product-view__main-details-bottom-spacing: $spacer--medium !default;

$product-view__order-gallery: 1 !default;
$product-view__order-main-details: 2 !default;
$product-view__order-secondary-details: 3 !default;

$product-view__price-bottom-margin: $spacer--medium !default;
$product-view__price-bottom-margin\@medium: $spacer--medium !default;
$product-view__price-color: $gray-darker !default;
$product-view__price-color--old: $gray !default;
$product-view__price-color--special: $gray-darker !default;
$product-view__price-font-size: 40px !default;
$product-view__price-font-family: $font-family-alt;
$product-view__price-font-size--small: $font-size-small !default;
$product-view__price-font-weight: $font-weight-bold !default;
$product-view__price-label-font-weight: $font-weight-normal !default;

$product-view__qty-margin-right\@large: $spacer--medium !default;

$product-view__rating-link-color: $gray-dark !default;
$product-view__rating-width: 100% !default;
$product-view__rating-width\@medium: 45% !default;
$product-view__rating-top: 0 !default;
$product-view__rating-right: 0 !default;
$product-view__rating-margin: 0 0 14px 0 !default;
$product-view__rating-margin\@medium: 0 !default;
$product-view__rating-text-color: $gray-dark !default;
$product-view__rating-value-font-size: $font-size-base !default;

$product-view__sku-color: $gray !default;
$product-view__sku-margin-bottom: $spacer--medium !default;
$product-view__sku-margin-bottom\@medium: $spacer !default;
$product-view__sku-padding-right: 55% !default;
$product-view__sku-width: 100%;

$product-view__stock-color: $gray-dark !default;
$product-view__stock-color--out-of-stock: $red !default;
$product-view__stock-color--alert: $red !default;
$product-view__stock-font-weight\@medium: $font-weight-bold !default;
$product-view__stock-text-transform\@medium: uppercase !default;

$product-view__swatch-margin-bottom: $spacer--medium !default;
$product-view__swatch-margin-right: $spacer--medium !default;
$product-view__swatch-option-title-font-size: $font-size-base !default;
$product-view__swatch-option-title-font-weight: $font-weight-bold !default;

$product-view__slider-name-font-size: $font-size-medium !default;
$product-view__slider-margin-bottom: $spacer !default;
$product-view__slider-margin-bottom\@medium: 72px !default;
$product-view__slider-margin-bottom--last\@medium: 56px !default;
$product-view__slider-margin-bottom--last\@large: 64px !default;
$product-view__slider-heading-font-size: 24px !default;
$product-view__slider-heading-font-size-weight: $font-weight-bold;
$product-view__slider-heading-text-transform: uppercase !default;

$product-view__tabs-margin: 0 (-$spacer) 14px (-$spacer) !default;
$product-view__tabs-margin\@medium: 0 0 72px 0 !default;

$product-view__additional-content-margin-bottom\@medium: 72px !default;

$product-view__review-item-padding: $spacer--large 0 !default;
$product-view__review-item-border: 1px solid $gray-light !default;
$product-view__review-details-margin-bottom: $spacer--medium !default;
$product-view__review-author-margin-bottom: $spacer !default;
$product-view__review-date-margin-bottom: $spacer !default;
$product-view__review-date-font-size: $font-size-small !default;
$product-view__review-date-color: $gray-light !default;
$product-view__review-content-title-font-weight: $font-weight-bold !default;
$product-view__review-product-name-font-weight: $font-weight-bold !default;

$product-view__wrapper-margin: $spacer--medium 0 $spacer--large 0 !default;
$product-view__wrapper-margin\@medium: 0 0 40px 0 !default;

$product-view__grouped-qty-margin: 0 !default;
$product-view__grouped-qty-max-width: 100px !default;
$product-view__grouped-qty-width: 120px !default;
$product-view__grouped-product-name-font-weight: $font-weight-bold !default;
$product-view__grouped-product-name-margin: 0 $spacer--medium 0 0 !default;
$product-view__grouped-product-name-margin\@medium: 0 0 $spacer !default;

$product-view__font-size-discount: $font-size-medium;
$product-view__action-item-height: 48px;
$product-view__filter-min-width: 160px;

.product-view {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: $product-view__wrapper-margin;

        @include mq($screen-m) {
            display: block;
            margin: $product-view__wrapper-margin\@medium;
        }
    }

    &__form {
        width: 100%;
    }

    &__gallery {
        order: $product-view__order-gallery;
        width: 100%;
        border-bottom: $product-view__gallery-bottom-border;
        padding-bottom: $product-view__gallery-padding-bottom;
        margin-bottom: $product-view__gallery-margin-bottom;

        @include mq($screen-m) {
            border-bottom: $product-view__gallery-bottom-border\@medium;
            padding-bottom: $product-view__gallery-padding-bottom\@medium;
            margin-bottom: $product-view__gallery-margin-bottom\@medium;
            float: left;
            width: $product-view__col-left-width;
        }
    }

    &__main-details {
        width: 100%;
        order: $product-view__order-main-details;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include mq($screen-m) {
            display: block;
            width: $product-view__col-right-width;
            position: relative;
            float: right;
            margin-bottom: $product-view__main-details-bottom-spacing;
        }

        .magezon-builder {
            width: 100%;

            .mgz-container {
                width: unset;

                & > .mgz-element-column {
                    & > .mgz-element-inner {
                        padding: 0 0 25px 0;
                    }
                }
            }

            .product-view__description + & {
                .mgz-container {
                    & > .mgz-element-column {
                        & > .mgz-element-inner {
                            padding: 25px 0 25px 0;
                        }
                    }
                }
            }
        }
    }

    &__manufacturer {
        margin-bottom: $spacer;
    }

    &__secondary-details {
        display: flex;
        flex-wrap: wrap;
        order: $product-view__order-secondary-details;
        width: 100%;

        @include mq($screen-m) {
            display: block;
            float: right;
            width: $product-view__col-right-width;
        }

        .filter {
            display: none;
            &__option {
                .choiches {
                    min-width: $product-view__filter-min-width;
                }
            }
        }
    }

    &__title {
        width: 100%;
        margin-bottom: $product-view__base-elements-vertical-spacing;
        text-transform: uppercase;

        .heading--page {
            margin: 0;
            font-size: $product-view__title-font-size;
            color: $product-view__title-color;
            text-transform: inherit;
            line-height: 1;
        }
    }

    &__price {
        display: flex;
        justify-content: flex-end;
        font-family: $product-view__price-font-family;
        font-size: $product-view__price-font-size;
        font-weight: $product-view__price-font-weight;
        margin-bottom: $product-view__price-bottom-margin;

        @include mq($screen-m) {
            margin-bottom: $product-view__price-bottom-margin\@medium;
        }

        .price__discount {
            font-size: $product-view__font-size-discount;
            font-family: $font-family-base;
            position: absolute;
            top: 0;
            right: 0;
        }

        .price-box {
            display: flex;

            .old-price {
                .price {
                    text-decoration: line-through;
                }
            }

            .special-price {
                color: $design-red;
                margin-right: 20px;
            }
        }

        .price-label {
            font-weight: $product-view__price-label-font-weight;
        }

        .price__value {
            color: $product-view__price-color;

            &--old {
                color: $product-view__price-color--old;
            }

            &--special {
                color: $product-view__price-color--special;
            }

            &.sly-old-price {
                display: none;
            }
        }
    }

    &__sku {
        padding-bottom: 25px;
        display: block;
        width: $product-view__sku-width;
    }

    &__description {
        order: 2;
        color: $product-view__description-color;
        width: 100%;
        padding: $product-view__description-padding\@medium;
        margin: $product-view__description-margin\@medium;
        line-height: $product-view__description-line-height;
    }

    &__description-bundle {
        order: 0;
        margin: $product-view__description-bundle-margin;
        padding: $product-view__description-bundle-padding;
    }

    &__description-title {
        font-weight: $product-view__description-title-font-weight;
    }

    &__customization {
        width: 100%;
        margin-bottom: $product-view__customization-space;
        padding-bottom: $product-view__customization-space;
        border-bottom: $product-view__customization-border;
    }

    &__summary {
        width: 100%;
        padding: $product-view__summary-padding;
        margin: $product-view__summary-margin;
        border-bottom: $product-view__summary-border;
        border-top: $product-view__summary-border;

        @include mq($screen-m) {
            border-bottom: 0;
            margin: 0;
        }
    }

    &__summary-price {
        display: flex;
        align-items: center;
        margin-bottom: $product-view__summary-price-margin-bottom;
        font-weight: $product-view__summary-price-font-weight;
        font-size: $product-view__summary-price-font-size;
    }

    &__summary-unit {
        padding-left: $product-view__summary-unit-padding-left;
        font-size: $product-view__summary-unit-font-size;
    }

    &__summary-title {
        font-weight: $product-view__summary-title-font-weight;
        margin-bottom: $product-view__summary-item-space;
    }

    &__summary-list {
        padding-left: $product-view__summary-item-space;
    }

    &__summary-item-title {
        display: block;
        font-weight: $product-view__summary-title-font-weight;
    }

    &__options {
        width: 100%;

        .swatch {
            margin-bottom: $product-view__swatch-margin-bottom;

            &__option-container {
                margin-right: $product-view__swatch-margin-right;
            }
        }
    }

    &__swatch-option-title {
        font-size: $product-view__swatch-option-title-font-size;
        font-weight: $product-view__swatch-option-title-font-weight;
    }

    &__tabs {
        margin: $product-view__tabs-margin;

        @include mq($screen-m) {
            margin: $product-view__tabs-margin\@medium;
        }

        .table {
            margin: 0;
        }
    }

    &__tab {
        .tab__content {
            line-height: $product-view__description-line-height;
        }
    }

    &__information {
        order: 3;
        width: 100%;
        padding: $product-view__information-padding;

        @include mq($screen-m) {
            padding: $product-view__information-padding\@medium;
        }
    }

    &__stock-status {
        color: $product-view__stock-color;
        width: 100%;
        margin-bottom: $product-view__price-bottom-margin;

        @include mq($screen-m) {
            margin-bottom: 0;
            font-weight: $product-view__stock-font-weight\@medium;
            text-transform: $product-view__stock-text-transform\@medium;
            position: absolute;
            text-align: right;
            bottom: 0;
        }

        &--out-of-stock {
            color: $product-view__stock-color--out-of-stock;
        }

        &--alert {
            color: $product-view__stock-color--alert;
        }
    }

    &__rating {
        color: $product-view__rating-text-color;
        width: $product-view__rating-width;
        display: flex;
        margin: $product-view__rating-margin;
        justify-content: space-between;
    }

    &__rating-rates {
        width: 50%;
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        font-size: $product-view__rating-value-font-size;

        @include mq($screen-m) {
            width: auto;
            justify-content: flex-end;
        }
    }

    &__rating-actions {
        width: 50%;
        flex: 1 0 auto;
        text-align: right;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__discount {
        position: absolute;
        top: 0;
        right: 0;
        font-size: $product-view__price-font-size--small;
    }

    &__main-actions {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: $product-view__main-actions-margin-bottom;
        flex-wrap: wrap;

        @include mq($screen-l) {
            flex-wrap: nowrap;
            align-items: flex-end;
        }
    }

    &__actions {
        clear: both;
    }

    &__extra-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        padding: $product-view__extra-actions-padding;

        @include mq($screen-l) {
            margin-bottom: $product-view__extra-actions-margin-bottom\@large;
            padding: 0;
        }
    }

    &__qty {
        flex-basis: 100%;
        margin: $product-view__extra-actions-margin;

        @include mq($screen-s) {
            flex: 0 0 auto;
        }

        @include mq($screen-l) {
            margin-bottom: $product-view__extra-actions-margin-bottom\@large;
            margin-right: $product-view__qty-margin-right\@large;
            margin: $product-view__extra-actions-margin-large;
        }
    }

    &__grouped-qty {
        margin: $product-view__grouped-qty-margin;
        max-width: $product-view__grouped-qty-max-width;
    }

    &__grouped-table {
        table-layout: fixed;
    }

    &__grouped-col-qty {
        width: $product-view__grouped-qty-width;
    }

    &__grouped-product-name {
        display: block;
        font-weight: $product-view__grouped-product-name-font-weight;
        margin: $product-view__grouped-product-name-margin;

        @include mq($screen-m) {
            margin: $product-view__grouped-product-name-margin\@medium;
        }
    }

    &__action-elements {
        flex-flow: column;
        flex: 1 0 auto;
        justify-content: space-between;
        display: flex;

        @include mq($screen-s) {
            margin-top: $product-view__action-elements-margin-top\@small;
        }

        @include mq($screen-l) {
            margin-top: $product-view__action-elements-margin-top\@large;
            flex-flow: row nowrap;
            justify-content: flex-end;
        }
    }

    &__add-to-cart-btn,
    &__update-cart-btn {
        flex: 1 0 auto;
        min-width: $product-view__add-to-cart-btn-min-width;

        @include mq($screen-m + 1px) {
            width: 25%;
        }

    }

    &__add-to-cart,
    &__update-cart {
        width: 100%;
        text-align: right;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__update-wishlist {
        width: $product-view__update-wishlist-btn-width;
    }

    &__action {
        margin-right: $product-view__action-item-spacing;
        padding: 0;

        &:hover {
            cursor: pointer;

            .icon {
                fill: $product-view__action-icon-color-hover;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &--button {
            box-shadow: none;
            background: transparent;
            border: none;
        }

        .icon {
            display: inline-block;
            fill: $product-view__action-icon-color;
            transition: $product-view__action-icon-transition;
        }

        &--wishlist {
            padding: $product-view__action-icon-wishlist-padding;
            height: $product-view__action-item-height;
            display: flex;
            align-items: center;

            .icon {
                width: $product-view__action-icon-wishlist-width;
                height: $product-view__action-icon-wishlist-height;
            }

            span {
                margin-left: $spacer--medium;
                color: $design-blue;
                text-decoration: underline;
            }
        }

        &--emailfriend {
            padding: $product-view__action-icon-emailfriend-padding;

            .icon {
                width: $product-view__action-icon-emailfriend-width;
                height: $product-view__action-icon-emailfriend-height;
            }
        }

        &--compare {
            padding: $product-view__action-icon-compare-padding;

            .icon {
                width: $product-view__action-icon-compare-width;
                height: $product-view__action-icon-compare-height;
            }
        }
    }

    &__link {
        &--rating {
            text-decoration: none;
            color: $product-view__rating-link-color;

            &:hover {
                cursor: pointer;
            }
        }

        &--add-review {
            text-decoration: none;
            font-size: $product-view__add-review-link-font-size;

            @include mq($screen-m) {
                font-size: $product-view__add-review-link-font-size\@medium;
            }
        }
    }

    &__brand {
        width: 100%;
        color: $product-view__sku-color;
        margin-bottom: $product-view__sku-margin-bottom;
        text-transform: uppercase;

        @include mq($screen-m) {
            display: block;
            margin-bottom: $product-view__sku-margin-bottom\@medium;
            padding-right: $product-view__sku-padding-right;
        }
    }

    &__review {
        @include mq($screen-m) {
            width: 430px;
        }
    }

    &__review-item {
        width: 100%;
        padding: $product-view__review-item-padding;
        border-top: $product-view__review-item-border;

        &:first-child {
            border-top: 0;
        }

        &:last-child {
            border-bottom: $product-view__review-item-border;
        }
    }

    &__review-details {
        margin-bottom: $product-view__review-details-margin-bottom;

        @include mq($screen-m) {
            margin-bottom: 0;
        }
    }

    &__review-author {
        margin-bottom: $product-view__review-author-margin-bottom;
    }

    &__review-date {
        margin-bottom: $product-view__review-date-margin-bottom;
        font-size: $product-view__review-date-font-size;
        color: $product-view__review-date-color;
    }

    &__review-content-title {
        font-weight: $product-view__review-content-title-font-weight;
    }

    &__review-title {
        margin-bottom: 0;
        text-transform: uppercase;
    }

    &__review-product-name {
        font-weight: $product-view__review-product-name-font-weight;
    }

    &__slider {
        margin-bottom: $product-view__slider-margin-bottom;

        @include mq($screen-m) {
            margin-bottom: $product-view__slider-margin-bottom\@medium;
        }

        &:last-child {
            @include mq($screen-m) {
                margin-bottom: $product-view__slider-margin-bottom--last\@medium;
            }

            @include mq($screen-l) {
                margin-bottom: $product-view__slider-margin-bottom--last\@large;
            }
        }

        .heading {
            font-size: $product-view__slider-heading-font-size;
            font-weight: $product-view__slider-heading-font-size-weight;
            text-transform: $product-view__slider-heading-text-transform;
        }
    }

    &__slider-navigation {
        @include mq($screen-l) {
            display: none;
        }
    }

    &__additional-content {
        display: none;

        @include mq($screen-m) {
            display: block;
            margin-bottom: $product-view__additional-content-margin-bottom\@medium;
        }
    }

    &__grid-item {
        .catalog-grid-item__name {
            font-size: $product-view__slider-name-font-size;
            text-transform: initial;
        }
    }

    &__slider {
        overflow: hidden;

        .slider__handler {
            margin-right: -$spacer--medium;
        }
    }

    &__slide-item {
        padding-right: $spacer--medium;
    }

    &__read-more {
        display: none;
        margin-top: $spacer;
        text-decoration: underline;

        @include mq($screen-m) {
            display: block;
        }
    }
}

.grouped-product-filter-wrapper {
    width: 100%;
}
