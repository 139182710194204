$input__field-padding: 0 $spacer--medium !default;
$input__field-padding--textarea: $spacer--medium !default;
$input__field-spacing: 40px !default;
$input__field-border: 1px solid $form-elements-border-color !default;
$input__field-border-radius: $form-elements-radius !default;
$input__field-border-radius--textarea: $form-elements-radius !default;
$input__field-line-height--textarea: $font-line-height !default;
$input__margin-bottom: $spacer--medium !default;
$input__placeholder-color: $font-color-placeholder !default;
$input__placeholder-color--dark: $gray;
$input__placeholder-font-size: $font-size-medium;
$input__label-margin-right: $spacer--medium !default;
$input__min-height--textarea: 100px !default;
$input__date-background-image: url("../images/icons/calendar.svg") !default;
$input__date-background-size: 25px 25px !default;
$input__date-background-repeat: no-repeat !default;
$input__date-background-position: center center !default;

// Additional variables
$input-field__input-height: 32px;
$input-field__border-radius: $border-radius;
$input-field__font-size: $font-size-medium;
$input-field__background-color: $gray-light;
$input-field__placeholder-color: $gray-light;

.input {
    margin-bottom: $input__margin-bottom;

    &--inline {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .input__label {
            @extend .label--inline;
            margin-right: $input__label-margin-right;
        }
    }

    &__field {
        width: 100%;
        height: $input-field__input-height;
        padding: $input__field-padding;
        border: none;
        border-radius: $input-field__border-radius;
        font-size: $input-field__font-size;
        line-height: normal;
        background: $input-field__background-color;
        transition: $transition-base;
        box-shadow: $design-box-shadow;

        &::placeholder {
            color: $input__placeholder-color;
            font-size: $input__placeholder-font-size;
            overflow: visible;
        }

        &--textarea {
            display: block;
            border-radius: $input__field-border-radius--textarea;
            min-width: 100%;
            max-width: 100%;
            min-height: $input__min-height--textarea;
            padding: $input__field-padding--textarea;
            line-height: $input__field-line-height--textarea;
        }

        &--white {
            background-color: white;

            &::placeholder {
                color: $input__placeholder-color--dark;
            }
        }
    }

    .ui-datepicker-trigger {
        @extend .button;
        @extend .button--icon;
        background-image: $input__date-background-image;
        background-size: $input__date-background-size;
        background-repeat: $input__date-background-repeat;
        background-position: $input__date-background-position;

        &:hover,
        &:focus {
            background-image: $input__date-background-image;
            background-size: $input__date-background-size;
            background-repeat: $input__date-background-repeat;
            background-position: $input__date-background-position;
        }

        span {
            display: none;
        }
    }
}

// Generic custom checkbox & radio styling
$input__size: 18px;
$input__border-color: #7f7f7f;
$input__checkmark-height: 12px;
$input__checkmark-width: 6px;
$input__checkmark-color: $design-red;
$input__border-radius--checkbox: 3px;
$input__border-radius--radio: 50%;
$input__border-width: 0 2px 2px 0;

// shared input styling, input[name="shipping_method"] excluded
input[type="checkbox"],
input[type="radio"] {
    &:not([name="shipping_method"]) {
        opacity: 0;

        // scale hidden input
        @supports (zoom: 1.25) {
            zoom: 1.25;
        }
        @supports not (zoom: 1.25) {
            transform: scale(1.25);
        }
        // "Wie is de beste" -H. Chow, 2020, 2019, 2018, 2017, 2016, 2015, ..

        & + * {
            // create faux input in :before
            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 0;
                height: $input__size;
                width: $input__size;
                border: 2px solid $input__border-color;
            }
        }

        &:checked {
            // add checkmark in :after when :checked
            & + * {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: ($input__size - $input__checkmark-height);
                    left: ($input__size - $input__checkmark-width) / 2;
                    width: $input__checkmark-width;
                    height: $input__checkmark-height;
                    border: solid $input__checkmark-color;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }
    }
}

// checkbox specific styling
input[type="checkbox"] {
    & + * {
        &:before {
            border-radius: $input__border-radius--checkbox;
        }
    }
}

// radio specific styling
input[type="radio"] {
    & + * {
        &:before {
            border-radius: $input__border-radius--radio;
        }
    }
}
