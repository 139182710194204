// Fractal
// @import "../../../../styles/variables";
// Magento
// @import "../../../styles/variables";

/*
* Custom Hoofcare variables (based on styleguide), which are not part of the theme.
*/

// Colors
$design-black: #000;
$design-white: #fff;
$design-grey-1: #2c2c2c;
$design-grey-2: #3f3f3f;
$design-grey-3: #f5f5f5;
$design-red: #da281c;
$design-green: #36bd0f;
$design-blue: #447fd0;
$design-orange: #eb8c19;

$design-red-darken: darken($design-red, 10%);
$design-green-darken: darken($design-green, 10%);

// Typography
$design-font-family-sans-serif: "";
$design-font-family-monospace: "";

$design-font-size-extra-small: 10px;
$design-font-size-small: 12px;
$design-font-size-base: 14px;
$design-font-size-medium: 16px;
$design-font-size-large: 18px;
$design-font-size-extra-large: 20px;

// Components
$border-radius--small: 2px;
$design-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.33);

// Page padding
$page__padding-top: 150px; // Equals height of header.
$page__padding-top--mobile: 90px; // Equals height of mobile header.

/**
  * Existing theme variables. Use these when styling components.
  */

// Colors
$white: $design-white !default;
$black: $design-black !default;
$red: $design-red !default;
$orange: $design-orange !default;
$yellow: #ffd500 !default;
$green: $design-green !default;
$blue: $design-blue !default;
$teal: #5bc0de !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;

// Grayscale colors
$gray-darkest: #333333 !default;
$gray-darker: #393243 !default;
$gray-dark: $design-grey-1 !default;
$gray: $design-grey-2 !default;
$gray-light: $design-grey-3 !default;
$gray-lighter: #c9c9c9 !default;
$gray-lightest: #f7f7f9 !default;

// Semantic color scheme
$color-primary: $red !default;
$color-secondary: $gray !default;
$color-success: $green !default;
$color-info: $teal !default;
$color-warning: $orange !default;
$color-danger: $red !default;
$color-inverse: $gray-dark !default;
$color-focus: $blue !default;

// Background
$bg-color-base: $white !default;
$bg-color-dark: $black !default;

// Border
$border-color-base: $gray-darker !default;
$border-width-base: 1px !default;
$border-style-base: solid !default;

$border-base: $border-width-base $border-style-base $border-color-base !default;
$border-focus: none !default;
$border-focus-dark: none !default;

// Outline
$outline-base: none !default;

// Focus inline
$color-focus-inline: $white !default;
$color-focus-inline-dark: $gray-darkest !default;
$bg-focus-inline: $color-focus !default;
$bg-focus-inline-dark: $color-primary !default;
$outline-focus-inline: $outline-base !default;
$text-decoration-focus-inline: underline !default;

// Spacers
$spacer--small: 4px !default;
$spacer: 8px !default;
$spacer--medium: 16px !default;
$spacer--large: 32px !default;
$spacer--extra-large: 48px !default;

// Typography
$font-family-sans-serif: "Poppins", sans-serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !default;

$font-family-base: $font-family-sans-serif !default;
$font-family-alt: "TradeGothic", sans-serif;
$font-family-heading: "TradeGothic", sans-serif;

$font-color-light: $gray-light !default;
$font-color-base: $gray !default;
$font-color-dark: $gray-dark !default;
$font-color-placeholder: lighten($gray, 10%);
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;
$font-weight-base: $font-weight-normal !default;
$font-line-height: 2 !default;

$font-size-xxl: 48px;
$font-size-extra-large: $design-font-size-extra-large !default;
$font-size-large: $design-font-size-large !default;
$font-size-medium: $design-font-size-medium !default;
$font-size-base: $design-font-size-base !default;
$font-size-small: $design-font-size-small !default;
$font-size-extra-small: $design-font-size-extra-small !default;

$headings-text-transform: uppercase;

// Layout
$max-content-width: 1328px !default;
// "69/420" - E. Renkema, 2020

// Layout - breakpoints
$screen-xs: 320px !default;
$screen-s: 480px !default;
$screen-m: 768px !default;
$screen-l: 992px !default;
$screen-xl: 1200px !default;
$screen-xxl: 1600px !default;

// Components
$border-radius: 4px !default;
$form-elements-radius: 4px !default;
$form-elements-radius--small: 2px !default;
$form-elements-border-color: $gray-light !default;
$form-elements-border-color-focus: $gray !default;
$form-input-box-shadow-focus: 0 4px 6px 0 rgba(57, 50, 67, 0.3) !default;
$transition-base: all 0.3s ease-in-out, outline-offset 1ms !default;
$transition-fade: opacity 0.25s linear !default;

$page__padding-top: 150px; // Equals height of header.
$page__padding-top--mobile: 90px; // Equals height of mobile header.
