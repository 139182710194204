$select__margin-bottom: $spacer--medium !default;
$select__padding: 0 $spacer--large 0 $spacer--medium !default;
$select__border: 1px solid $form-elements-border-color !default;
$select__spacing: 36px !default;
$select__border-radius: $border-radius !default;
$select__field-background: $white !default;
$select__input-border-color: $form-elements-border-color !default;
$select__field-list-border-radius--single: $border-radius !default;

// Additional variables
$select__background-color: $gray-light;
$select__min-width: 150px;
$select__field-box-shadow: $design-box-shadow;

.select {
    border-radius: $select__border-radius;
    z-index: 2;

    @include mq($screen-m) {
        min-width: $select__min-width;
    }

    &__field {
        position: relative;
        background: $select__field-background;
        box-shadow: $select__field-box-shadow;
        border-radius: $select__border-radius;;

        &--native {
            width: 100%;
            height: $select__spacing;
            padding: $select__padding;
            border-radius: $select__border-radius;
            appearance: none;
            transition: $transition-base;
            box-shadow: $design-box-shadow;

            border: none;
            background: $select__background-color;
        }
    }

    &__field-item {
        position: relative;
        height: $select__spacing;
        line-height: $select__spacing;
        padding: $select__padding;
        cursor: pointer;
        font-size: $font-size-base;
        // "Dikke doei!" - B. Dijksterhuis, 2020
    }

    &__field-list {
        &--single {
            height: $select__spacing;
            line-height: $select__spacing;
            padding: 0;
            border-radius: $select__border-radius;
            background: $select__background-color;
        }
    }

    &__field-input {
        transition: $transition-base;

        &:focus {
            outline: none;
        }
    }
}

// Choices.js lib styles overwrites
.choices {
    border: none;
    animation: fade-in 1.5s;
    &.is-open {
        .select__field-list {
            &--single {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: $select__field-list-border-radius--single;
                border-top-right-radius: $select__field-list-border-radius--single;
                border-bottom: 0;
            }
        }
    }

    &.is-focused {
        .select__field-list {
            box-shadow: $select__field-box-shadow;
            // transition: $transition-base;
            // @include focus-input();
        }
    }

    &__list--dropdown {
        max-height: 300px;
        overflow: auto;
        border: none;

        .is-open & {
            border-radius: 0 0 $select__border-radius $select__border-radius;
            border-color: transparent;
        }

        & .choices__item--selectable {
            padding-right: $spacer--medium;
        }
    }
}

