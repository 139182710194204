.am-ranges {
    ol.items {
        li.item {
            position: relative;
            margin-bottom: $spacer;

            &:not(:last-of-type) {
                margin-bottom: $spacer;
            }
        }
    }
}
