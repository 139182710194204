/*
** File holds basic/generic styling, essentially a css reset.
** Mainly used for shared tag-based styling.
*/

$page__padding-top: 150px; // Equals height of header.
$page__padding-top--mobile: 100px; // Equals height of mobile header.

*,
*::after,
*::before {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html,
body {
    height: 100%;
    min-width: 320px;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    color: $font-color-base;
}

body {
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    flex: 1 0 auto;
    padding-top: $page__padding-top--mobile;

    @include mq($screen-m + 1px) {
        padding-top: $page__padding-top;
    }
}

.page-main {
    padding-top: $spacer--medium;
    @include mq($screen-m) {
        padding-top: $spacer;
    }
}

div.home {
    margin-top: -$spacer--medium;
    @include mq($screen-m + 1px) {
        padding-top: $spacer;
    }
}

p {
    margin: 0;
    padding: 0;
}

dl,
dt,
dd {
    margin: 0;
}

a {
    &:focus {
        outline: none;
    }
}

input {
    margin: 0 !important;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
