/*
** File holds styling that should ideally be moved to more specific stylesheets and/or fixed in another way.
** Comments are included hinting where styling could go and/or what purpose it serves.
*/

// remove from template files
.amasty-extrafee-title {
    display: none;
}

// relative parents needed for absolutely positioned inputs
.payment-method,
.amcheckout-method,
.amasty-extrafee-option {
    position: relative;
}

// move to relevant scss file(s)
form.methods-shipping {
    @include mq($screen-s) {
        padding: $spacer--large 0;
    }
}

.table-checkout-shipping-method {
    tr {
        padding: 0 $spacer;
    }
    td {
        border-top: none !important;

        &.col-error {
            width: 100% !important;
        }
    }
}

.category-description {
    margin-bottom: $spacer--large;
    line-height: $font-line-height;
}

$attachment-icon__width: 35px;

.attachment-table {
    .attachment-icon {
        img {
            width: $attachment-icon__width;
        }
    }
}

.price-box {
    .minimal-price {
        .price-label {
            color: #7f7f7f;
        }
    }
}

#product-attribute-specs-table {
    tr {
        th,
        td {
            width: 50%;
        }
    }
}


// Centers modal, regardless of width/height etc. (wip)
.modal-popup {
    display: flex;
    align-items: center;
}

// .modal-popup.modal-slide .modal-inner-wrap {
//     margin: 0 !important;
// }
