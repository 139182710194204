.checkout-index-index {
    .opc-wrapper {
        &.am-opc-wrapper {
            .label {
                span {
                    word-break: initial;
                }
            }
        }
    }
} 