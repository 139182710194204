$quick-order__link-color: $red;
$quick-order__box-shadow--hover: none !important;

.quick-order {
    margin: $spacer--large 0;
}

.quickod {
    &-wrapper {
        &:hover {
            box-shadow: $quick-order__box-shadow--hover;
        }
    }

    &-add-multiple {
        #qodupcsv {
            margin-bottom: $spacer;
        }
        #downloadcsvfile {
            a {
                margin-left: 0;
                color: $quick-order__link-color;
            }
        }
    }
}

.quickorder-link {
    padding: $spacer--medium $spacer--medium $spacer--medium $spacer;
    margin-left: auto;

    &.-desktop {
        padding: 0;
    }

    #quickorder-link {
        background: none !important;
    }

    a {
        @extend .button;
        height: auto;
        text-align: center;
        line-height: 2;
        padding: $button__padding--quick-order;
    }
}
