/*
** File holds page-specific styling/overrides.
** Comments are included hinting where styling could go and/or what purpose it serves.
*/

.page {
    // Products page.
    &-products {
        .breadcrumbs {
            margin-top: 20px;
            margin-bottom: 0;

            &__item {
                font-size: 14px;

                &:not(:first-child){
                    &:before {
                        content: ">";
                        display: flex;
                        align-items: center;
                        margin-right: $spacer;
                    }
                }

                &:after {
                    content: "";
                }

                &:last-child {
                    &:not(:first-child) {
                        padding: 0;
                    }
                }
            }

            &__link {
                padding: $spacer 0;
            }
        }
    }

    // Checkout page.
    &-layout-checkout {
        .page-main .form-shipping-address {
            .field {
                margin-bottom: 10px !important;
            }
        }
        .estimated-section__counter {
            &__counter {
                background-color: $red !important;
            }
        }

        .step-title {
            font-size: $font-size-extra-large;
        }

        .shipping-policy-block {
            height: unset !important;
            top: 0 !important;
        }

        .checkout-payment-method {
            .payment-method-title {
                padding: $spacer--medium 0 !important;

                .label {
                    margin: 0;
                    position: relative;
                }
            }

            .checkout-agreements-block {
                display: flex;
                margin: 0 0 10px 0;

                .checkout-agreement.field.choice {
                    margin: 0;
                    padding: 0;
                    display: flex;

                    input[type="checkbox"] {
                        margin: 0 0 0 -10px;
                        height: 1px;
                        width: 1px;
                    }

                    input[type="checkbox"]:not([name="shipping_method"]):checked + .label:after,
                    input[type="radio"]:not([name="shipping_method"]):checked + .label:after {
                        order: -1;
                        position: absolute;
                        top: 1px;
                        left: 3px;
                        content: "";
                    }

                    .label {
                        display: flex;
                        margin: 0;
                        justify-content: flex-start;
                        align-items: flex-start;
                        position: relative;
                        cursor: pointer;

                        &::before {
                            position: unset;
                            top: unset;
                            left: unset;
                            display: block;
                            height: 18px;
                            width: 18px;
                            font-size: 0;
                            line-height: 0;
                            margin: 0 10px 0 0;
                        }

                        &::after {
                            position: unset;
                            top: unset;
                            left: unset;
                            display: block;
                            line-height: 1.2rem;
                        }

                        .action.action-show {
                            display: block;
                            width: unset;
                        }
                    }
                }
            }
        }
    }
}
