$search-form__height: 34px !default;
$search-form__width: 100% !default;
$search-form__width--extra-large: 100% !default;
$search-form__alignment: flex-start !default;
$search-form__margin: 0 !default;
$search-form__border: none !default;
$search-form__input-height: 36px !default;
$search-form__button-size: 18px !default;
$search-form__button-margin: 0 !default;
$search-form__button-border: none !default;
$search-form__button-background: transparent !default;
$search-form__button-icon-color: lighten($design-grey-2, 50%) !default;
$search-form__button-fill-hover: $color-primary !default;
$search-form__border-radius: 3px;
$search-form__box-shadow: 1px 2px 2px 0 $gray;
$search-form__background-color: $gray-light;

.search-form {
    position: relative;
    width: $search-form__width;
    height: $search-form__height;
    align-self: $search-form__alignment;
    margin: $search-form__margin;
    border: $search-form__border;
    border-radius: $search-form__border-radius;
    z-index: 1;

    @include mq($screen-xl) {
        width: $search-form__width--extra-large;
    }

    &__input-wrapper {
        position: relative;
    }

    &__input {
        width: 100%;
        height: $search-form__input-height;
        border: none;
        border-radius: $search-form__border-radius;
        background: $search-form__background-color;

        &:focus {
            @include focus-input();
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        height: $search-form__input-height;
        margin: $search-form__button-margin;
        border: $search-form__button-border;
        border-radius: $search-form__height;
        background-color: $search-form__button-background;
        box-shadow: none;

        &:focus,
        &:hover {
            background-color: $search-form__button-background;

            .button__icon {
                fill: $search-form__button-fill-hover;
            }
        }

        &.button.button--icon.control {
            min-height: unset;

            .amasty-xsearch-loader {
                position: unset;
                top: unset;
                right: unset;
            }

            & :not(.amasty-xsearch-loader) {
                display: none;
            }
        }
    }

    .button__icon {
        width: $search-form__button-size;
        height: $search-form__button-size;
        fill: $search-form__button-icon-color;
    }
}
