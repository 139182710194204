$header__border-color: $gray-light !default;
$header__padding-bottom: $spacer;

$header__wrapper-padding: 0 !default;
$header__wrapper-padding\@medium: 18px 0 !default;

$header__logo-padding: 12px 0 12px $spacer !default;
$header__logo-padding\@medium: 0 0 0 $spacer !default;

$header__buttons-width: calc(100% - 48px) !default;
$header__buttons-width\@medium: calc(4 * 48px + 3 * #{$spacer}) !default;
$header__buttons-width\@extra-large: calc(
    4 * 48px + 3 * #{$spacer--medium}
) !default;
$header__buttons-button-width: 36px;

$header__search-wrapper-padding: 0 0 0 24px !default;
$header__search-wrapper-padding\@medium: 0 14px !default;

$header__logo-margin: 0 15px 0 auto;
$header__logo-margin-mobile: 0 auto;

// Additional variables
$header__button-background-color: $gray-light;
$header__toggle-background-color: $gray-light;
$header__background-color: $white;
$header__buttons-padding: 0 $spacer--medium;

$header__row-height: 90px;

$header__minicart-border-radius: $border-radius;
$header__minicart-background-color: $gray-light;
$header__minicart-margin: 0 -#{$spacer} 0 0;
$header__minicart-margin--mobile: $spacer--extra-large -#{$spacer} 0 0;

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateZ(0);
    border-bottom: none;
    background-color: $header__background-color;
    z-index: 999;
    padding-bottom: $header__padding-bottom;

    @include mq($screen-l) {
        padding-bottom: 0px;
    }

    &__row {
        display: flex;
        height: auto;
        flex-wrap: wrap;
        @include mq($screen-l) {
            flex-wrap: nowrap;
            height: $header__row-height;
        }
    }

    &__col {
        flex: 1;
        display: flex;
        align-items: center;
        flex-grow: 1;

        &.-left {
            order: 2;
            flex-grow: initial;
            min-width: 100%;
            @include mq($screen-l) {
                order: 0;
                min-width: initial;
                flex-grow: 1;
            }
        }

        &.-center {
            height: 54px;

            @include mq($screen-m) {
                height: 100px;
            }

            @include mq($screen-l) {
                height: 90px;
                order: 1;
                flex: 2;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }

        &.-right {
            justify-content: flex-end;
            flex: 0;

            .select select {
                display: none;
            }

            @include mq($screen-l) {
                order: 2;
            }
            // @include mq($screen-s) {
            //     flex: 1;
            // }
        }
    }

    .logo {
        z-index: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        order: 2;
        width: 50px;

        // @include mq($screen-m, $screen-l) {
        //     margin: 0 50px 0 0;
        // }

        @include mq($screen-l) {
            width: 100px;
            flex: unset;

            // margin: $header__logo-margin;
            margin-top: 10px;
            order: 0;
        }
    }

    .search-form {
        align-self: center;
        max-width: 100%;
    }

    .select {
        align-items: flex-end;
        justify-self: flex-end;
        margin: 0 20px 0 0;

        &__field {
            border-radius: $border-radius;
        }

        @include mq($screen-m) {
            margin: 0;
        }

        @include mq($screen-l) {
            margin: 0 0 0 auto;
        }

        .choices {
            animation: fade-in 1.5s;
        }
    }

    &__buttons {
        border-radius: $border-radius;
        display: flex;
        width: auto;
        max-width: $header__buttons-width;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        align-self: center;
        padding: $header__buttons-padding;

        @include mq($screen-m) {
            max-width: $header__buttons-width\@medium;
        }

        @include mq($screen-l) {
            background: $header__button-background-color;
            margin-left: $spacer--extra-large;
            box-shadow: $design-box-shadow;
        }

        @include mq($screen-xl) {
            max-width: $header__buttons-width\@extra-large;
        }

        &--account {
            .current-user {
                display: none;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                min-width: 200px;
                position: absolute;
                top: calc(#{$header__buttons-button-width} + 2px);
                padding: 16px;
                background: $header__button-background-color;
                right: -52px;
                box-shadow: $design-box-shadow;
                border-radius: $border-radius;
                color: #3f3f3f;
                z-index:2;

                @include mq($screen-s) {
                    right: -60px;
                }
            }

            &:hover, &:active, &:focus {
                .current-user {
                    display: flex;
                }
            }

            &.is-logged-in {
                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    background: $design-red;
                    position: absolute;
                    bottom: 7px;
                    right: 7px;
                }
            }
        }
    }

    &__minicart-content {
        border-radius: $header__minicart-border-radius;
        background-color: $header__minicart-background-color !important;
        margin: $header__minicart-margin--mobile !important;
        border: 1px solid $design-grey-2;

        @include mq($screen-s) {
            margin: $header__minicart-margin !important;
        }
    }

    &__flags {
        display: none;

        @include mq($screen-m) {
            display: initial;
        }
    }
}
