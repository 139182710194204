$rating__margin: $spacer--medium !default;
$review__info-column-width: 20% !default;
$review__decsription-column-width: 80% !default;
$review__summary-max-width\@large: 200px !default;
$review__box-background-color: $gray-light;
$review__box-border-radius: $border-radius;
$review__box-box-shadow: $design-box-shadow;
$review__title-font-weight: $font-weight-base;
$review__title-heading-font-weight: $font-weight-bold;
$review__author-font-size: $font-size-base;
$review__author-font-weight: $font-weight-base;
$review__date-font-size: $font-size-small;
$review__required-info-font-size: $font-size-small;
$review__amount-font-size: $font-size-small;

.review {
    width: 100%;

    @include mq($screen-m) {
        display: flex;
        flex-flow: row nowrap;
    }

    &--box {
        background-color: $review__box-background-color;
        border-radius: $review__box-border-radius;
        box-shadow: $review__box-box-shadow;
    }

    &--add {
        @include mq($screen-m) {
            display: block;
        }
    }

    &--summary {
        @include mq($screen-m) {
            display: flex;
        }

        .rating {
            margin-right: $spacer;
        }
    }

    &__details {
        width: 100%;
        padding: $spacer--medium;

        @include mq($screen-m) {
            &:first-child {
                width: $review__info-column-width;
            }

            &:last-child {
                width: $review__decsription-column-width;
            }
        }
    }

    &__author,
    &__date,
    &__title,
    &__rating,
    &__rating-legend {
        margin-bottom: $rating__margin;
    }

    &__title {
        font-weight: $review__title-font-weight;
        margin-bottom: 0;

        &-heading {
            font-weight: $review__title-heading-font-weight;
        }
    }

    &__title-product {
        display: block;
        margin: $spacer 0;
        text-transform: none;
        font-weight: bold;
    }

    &__author {
        font-size: $review__author-font-size;
        font-weight: $review__author-font-weight;
        text-transform: uppercase;
    }

    &__date {
        display: block;
        color: $gray;
        font-size: $review__date-font-size;
    }

    &__fieldset {
        max-width: 430px;
    }

    &__field {
        margin-bottom: $spacer--extra-large;
    }

    &__submit-btn {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__required-info {
        margin-top: $spacer--large;
        font-size: $review__required-info-font-size;
    }

    &__summary-handle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @include mq($screen-m) {
            flex-wrap: wrap;
            justify-content: flex-end;
            width: auto;
            max-width: $review__summary-max-width\@large;
        }
    }

    &__amount {
        font-size: $review__amount-font-size;
        text-decoration: underline;
        letter-spacing: 2px;
        margin-right: $spacer;

        @include mq($screen-m) {
            margin-right: 0;
        }

        &:focus {
            @include focus-inline();
        }

        &-text {
            display: none;
        }
    }

    &__summary-add {
        text-decoration: underline;
        text-align: right;

        &:focus {
            @include focus-inline();
        }
    }
}
