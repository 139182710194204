$am-filter__item-link-margin: 0 0 0 20px;

.am-filter {
    &-items {
        &-stock {
            .item {
                a {
                    margin: $am-filter__item-link-margin !important;
                }
            }
        }
    }
}
