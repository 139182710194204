$slider-handle__color: $red;
$search-box__margin: 0 0 $spacer--medium 0;

.amshopby {
    &-slider-container {
        .am-slider {
            .ui-slider-handle {
                background: $slider-handle__color !important;
            }
        }
    }
    &-search-box {
        margin: $search-box__margin;

        input {
            width: 100%;
        }
    }
}

.filter-options-content {
    .am-show-more {
        color: #447fd0;
        cursor: pointer;

        @include mq($screen-m) {
            &:hover,
            &:active,
            &:focus {
                color: #3f3f3f;
            }
        }

        &[data-is-hide="true"] {
            color: #447fd0;

            @include mq($screen-m) {
                &:hover,
                &:active,
                &:focus {
                    color: #3f3f3f;
                }
            }
        }
    }
    .items.-am-multiselect {
        .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & > a {
                display: block;

                & > .label {
                    margin: 0 0 0 8px;
                }
            }

            .amshopby-choice {
                margin: 0;
                padding: 0;
                display: block;
                order: -1;

                &:before {
                    position: static;
                    top: auto;
                    left: auto;
                    display: block;
                }
            }
        }
    }
}
