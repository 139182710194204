$brief-info__padding\@medium: $spacer--medium 0 $spacer--medium !default;
$brief-info__border: 1px $gray-light solid !default;
$brief-info__content-text-align: center !default;
$brief-info__content-text-align\@medium: left !default;
$brief-info__heading-margin: 0 0 $spacer--medium !default;
$brief-info__icon-bg-color: transparent !default;
$brief-info__icon-border-radius: 32px !default;
$brief-info__icon-delivery-truck-padding: 13px $spacer 13px 6px !default;
$brief-info__icon-delivery-truck-svg-height: 100% !default;
$brief-info__icon-delivery-truck-svg-padding: 0 !default;
$brief-info__icon-delivery-truck-svg-width: 100% !default;
$brief-info__icon-shield-padding: 12px 14px 12px 15px !default;
$brief-info__icon-shield-svg-height: 100% !default;
$brief-info__icon-shield-svg-width: 100% !default;
$brief-info__icon-size: 20px !default;
$brief-info__icon-padding: 0 $spacer--medium !default;
$brief-info__icon-padding\@medium: 0 !default;
$brief-info__icon-margin-bottom: $spacer !default;
$brief-info__icon-margin-bottom\@large: 0 !default;
$brief-info__link-font-size: $font-size-small !default;
$brief-info__link-font-weight: $font-weight-bold !default;
$brief-info__link-text-decoration: none !default;
$brief-info__title-font-size: $font-size-small !default;
$brief-info__title-font-size\@large: $font-size-base !default;
$brief-info__title-font-weight: $font-weight-normal !default;
$brief-info__title-line-height: 1em !default;
$brief-info__title-text-transform: none !default;
$brief-info__title-margin-bottom: 0 !default;

// Additional variables
$brief-info__icon-color: $design-green;

.brief-info {
    display: none;
    padding: $brief-info__padding\@medium;
    position: relative;
    z-index: 99;
    background: white;

    @include mq($screen-m) {
        display: block;
    }

    @include mq($screen-l) {
        background: $gray-light;
    }

    &__heading {
        width: 100%;
        margin: $brief-info__heading-margin;
        text-align: center;

        @include mq($screen-m) {
            text-align: left;
        }
    }

    &__items-container {
        display: flex;
        justify-content: space-between;
        padding: 0 $spacer--medium;
    }

    &__item {
        display: none;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: center;
        // padding: $brief-info__icon-padding;
        // It's related to IE10/11 bug, which is solved by adding unit to flex-basis
        // Detailed description:
        // https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
        // sass-lint:disable no-css-comments
        /* stylelint-disable length-zero-no-unit */
        flex: 1 0 0;
        /* stylelint-enable length-zero-no-unit */
        // sass-lint:enable no-css-comments
        &:nth-child(1) {
            display: flex;
        }

        @include mq($screen-xs) {
            &:nth-child(2) {
                display: flex;
            }
        }

        @include mq($screen-m) {
            align-items: flex-start;

            &:nth-child(3) {
                display: flex;
            }
        }
        @include mq($screen-l) {
            align-items: center;
            flex-flow: row nowrap;
            display: flex;
        }
        &:first-child,
        &:last-child {
            @include mq($screen-m) {
                padding: $brief-info__icon-padding\@medium;
            }
        }
    }

    &__icon-wrapper {
        display: flex;
        align-items: center;
        background-color: $brief-info__icon-bg-color;
        border-radius: $brief-info__icon-border-radius;
        width: $brief-info__icon-size;
        height: $brief-info__icon-size;
        flex-shrink: 0;
        @include mq($screen-l) {
            margin-bottom: $brief-info__icon-margin-bottom\@large;
        }
        &--delivery-truck {
            padding: $brief-info__icon-delivery-truck-padding;
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
        padding: 14px;
        fill: $brief-info__icon-color;
        &--delivery-truck {
            padding: $brief-info__icon-delivery-truck-svg-padding;
            width: $brief-info__icon-delivery-truck-svg-width;
            height: $brief-info__icon-delivery-truck-svg-height;
        }
        &--shield {
            padding: $brief-info__icon-shield-padding;
            width: $brief-info__icon-shield-svg-width;
            height: $brief-info__icon-shield-svg-height;
        }
    }

    &__title {
        margin-bottom: $brief-info__title-margin-bottom;
        font-size: $brief-info__title-font-size;
        text-transform: $brief-info__title-text-transform;
        line-height: $brief-info__title-line-height;
        font-weight: $brief-info__title-font-weight;
        margin: 0 0 0 10px;
        @include mq($screen-m) {
            font-size: $brief-info__title-font-size\@large;
        }
    }

    &__link {
        font-size: $brief-info__link-font-size;
        font-weight: $brief-info__link-font-weight;
        text-decoration: $brief-info__link-text-decoration;
    }
    &__content {
        text-align: $brief-info__content-text-align;
        @include mq($screen-m) {
            text-align: $brief-info__content-text-align\@medium;
        }
        &:before {
            content: url(../images/icons/checkmark.svg);
        }
    }
}
