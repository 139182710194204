$container__max-width: $max-content-width;
$container__padding: 0 8px;
$container__margin: 0 auto;

.container {
    max-width: $container__max-width;
    margin: $container__margin;
    padding: $container__padding;
    position: relative;
}
