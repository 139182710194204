$footer__padding: $spacer--medium !default;
$footer__handler-padding: calc(#{$spacer--extra-large} * 2) 0;
$footer__background-color: $black !default;
$footer__background-color\@large: $black !default;

$footer__newsletter-padding: 0 $spacer--medium !default;

$footer__link-color: $gray-lighter !default;
$footer__link-color-hover: $color-primary !default;
$footer__link-text-decoration-hover: none !default;

$footer__link-inner-padding: $spacer !default;

$footer__bottom-bar-background: $gray-darkest !default;
$footer__bottom-bar-background\@large: $gray-darkest !default;
$footer__bottom-bar-color: $gray-lighter !default;
$footer__bottom-bar-font-size: $font-size-small !default;

$footer__social-handl-margin: 0 0 $spacer 0 !default;
$footer__social-handl-margin\@medium: 0 !default;

$footer__social-icon-color: $white !default;
$footer__social-icon-size: 36px;

$footer__scroll-top-top: -24px !default;
$footer__icon-size: 13px !default;
$footer__icon-fill: $color-secondary !default;
$footer__icon-fill-hover: $color-primary !default;

$footer__bottom-bar-padding: $spacer--medium;
$footer__contact-details-padding: $spacer--medium;
$footer__height-logo: 100px;
$footer__text-color: $gray-lighter;

$footer__font-size: $font-size-base;
$footer__font-size--small: $design-font-size-small;

.footer {
    position: relative;
    flex-shrink: 0;
    background-color: $footer__background-color;
    color: $footer__text-color;
    font-size: $footer__font-size;

    *:not(.button) {
        color: inherit;
    }

    @include mq($screen-l) {
        background-color: $footer__background-color\@large;
    }

    @include fluid-font(
        $screen-l,
        $screen-xl,
        $footer__font-size--small,
        $footer__font-size
    );

    &__handler {
        margin: 0 auto $footer__padding;
        padding: $footer__handler-padding;
        position: relative;

        @include mq($screen-m) {
            margin: 0 auto;
        }

        @include mq($screen-l) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__newsletter {
        margin: $spacer--large auto $footer__padding auto;
        padding: $footer__newsletter-padding;

        @include mq($screen-m) {
            order: 3;
        }

        @include mq($screen-l) {
            padding-top: $footer__padding;
            margin: 0 auto $footer__padding;
        }

        .mgz-element-column {
            & > .mgz-element-inner {
                padding: 0;
                .mgz-btn {
                    color: $white;
                }
            }
        }
    }

    &__contact-details {
        display: flex;
        justify-content: center;
        flex-direction: column;

        padding: $footer__contact-details-padding;

        @include mq($screen-m) {
            order: 2;
        }

        @include mq($screen-l) {
            order: 1;
        }

        .logo {
            @include mq($screen-m) {
                display: block;
                margin-bottom: $spacer--medium;
                img {
                    width: auto;
                }
            }
        }

        address {
            margin-bottom: $spacer--large;
            text-align: center;

            span {
                display: block;
                font-style: normal;

                &:not(:last-child) {
                    margin-bottom: $spacer;
                }

                a {
                    text-decoration: underline;
                }
            }

            @include mq($screen-m) {
                text-align: left;
            }
        }
    }

    &__logo {
        align-self: center;
        height: $footer__height-logo;
        width: auto;

        margin-bottom: $spacer--large;

        @include mq($screen-m) {
            align-self: flex-start;
        }
    }

    &__links {
        @include mq($screen-l) {
            order: 2;
        }
        .dropdown-list {
            @include mq($screen-m) {
                padding: 0;
            }
        }

        .dropdown-list__item {
            @include mq($screen-m) {
                border-bottom: 0;
            }
        }

        .list__item {
            padding-left: $footer__link-inner-padding;
            @include mq($screen-m) {
                padding-left: 0;
            }
        }
    }

    &__links-list {
        padding-bottom: $footer__padding;
    }

    &__link {
        color: $footer__link-color;
        text-decoration: none;
        font-style: normal;

        &:hover {
            color: $footer__link-color-hover;
            text-decoration: $footer__link-text-decoration-hover;
        }

        &.-email {
            position: relative;
            padding-left: $spacer--large;
            text-decoration: underline;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: url("../images/icons/Mail.svg");
                display: inline-block;
                margin-right: $spacer;
            }
        }
    }

    &__bottom-bar {
        color: $footer__bottom-bar-color;
        background-color: $footer__bottom-bar-background;
        @include mq($screen-l) {
            background-color: $footer__bottom-bar-background\@large;
        }
    }

    &__bottom-bar-handler {
        padding: $footer__bottom-bar-padding;
        @include mq($screen-m) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
        }

        img {
            filter: grayscale(100%);
        }
    }

    &__social-handler {
        margin: $footer__social-handl-margin;

        .list__icon-link {
            &:focus {
                @include focus("dark");
            }
        }

        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            order: 1;
            margin: $footer__social-handl-margin\@medium;
        }
    }

    &__social-list-item {
        &:not(:last-child) {
            margin-right: $spacer--medium;
        }
        .list__icon-link {
            padding: 0;
        }
    }

    &__social-list-subtitle {
        display: none;
        @include mq($screen-m) {
            display: block;
            margin-right: $footer__padding;
            font-size: $footer__bottom-bar-font-size;
        }
    }

    &__social-icon {
        fill: $footer__social-icon-color;
        height: $footer__social-icon-size;
        width: $footer__social-icon-size;
    }

    &__email-icon {
        display: inline;
    }

    &__copyright {
        display: block;
        font-size: $footer__bottom-bar-font-size;
        text-align: center;
        margin-top: $spacer--medium;

        @include mq($screen-m) {
            margin-top: 0;
        }
    }

    &__scroll-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 10;
        display: none;

        .footer__icon {
            fill: $white;
        }

        &:hover {
            .footer__icon {
                fill: $footer__icon-fill-hover;
            }
        }
    }

    &__icon {
        width: $footer__icon-size;
        height: $footer__icon-size;
        fill: $footer__icon-fill;
    }

    &__payment-methods {
        display: none;
        @include mq($screen-m) {
            display: block;
            height: 40px;
            flex: 0;
            width: auto;

            img {
                filter: grayscale(100%);
            }
        }
    }

    &__logo-small {
        @include mq($screen-m) {
            display: none;
        }

        max-height: 30px;
        flex: 0;
    }
}
