/**
 * styles.scss
 * Global stylesheet that compiles the variables,
 * Components, vendor packages, mixins and components with the theme
 * @author: HCDesigns © 2020
 */

// -- Vendor packages
@import "../Snowdog_Components/01-globals/variables/variables";
@import "../Snowdog_Components/styles";

// -- Variables
// @import "../Werkman_Components/01-globals/variables/variables";
@import "./variables";

// -- Components
// @import "../Werkman_Components/styles";

// -- Styles
@import "./theme";
