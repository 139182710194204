$checkbox__line-height          : $font-line-height !default;
$checkbox__label-margin         : 0 !default;
$checkbox__label-padding        : 9px 26px !default;
$checkbox__spacer               : 18px !default;
$checkbox__top                  : 14px !default;
$checkbox__left                 : 0 !default;
$checkbox__icon-border-radius   : 2px !default;
$checkbox__icon-check-mark-color: $white !default;
$checkbox__icon-fill            : $color-primary !default;
$checkbox__icon-transition      : $transition-base !default;
$checkbox__border               : 2px solid $gray !default;
$checkbox__border--checked      : none !default;
$checkbox__color--fake          : $gray-dark !default;

.checkbox {
    position: relative;

    &--link {
        display: block;
        text-decoration: none;
        color: $checkbox__color--fake;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &--link-checked {
        display: block;

        .checkbox__icon {
            opacity: 1;
        }

        .checkbox__label:before {
            border: $checkbox__border--checked;
        }
    }

    &__field {
        position: absolute;
        top: $checkbox__top;
        left: $checkbox__left;
        opacity: 0;

        &:checked + .checkbox__icon {
            opacity: 1;
        }

        &:checked ~ .checkbox__label:before {
            border: $checkbox__border--checked;
        }

        &:focus ~ .checkbox__label {
            @include focus();
        }
    }

    &__label {
        display: block;
        margin: $checkbox__label-margin;
        padding: $checkbox__label-padding;
        line-height: $checkbox__line-height;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: $checkbox__top;
            left: $checkbox__left;
            width: $checkbox__spacer;
            height: $checkbox__spacer;
            border: $checkbox__border;
            border-radius: $checkbox__icon-border-radius;
        }
    }

    &__icon {
        position: absolute;
        top: $checkbox__top;
        left: $checkbox__left;
        width: $checkbox__spacer;
        height: $checkbox__spacer;
        fill: $checkbox__icon-fill;
        background: $checkbox__icon-check-mark-color;
        cursor: pointer;
        opacity: 0;
        transition: $checkbox__icon-transition;
    }
}
