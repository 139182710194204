// $table__padding: $spacer--medium !default;
$table__padding: $spacer !default;
$table__color: $black !default;
$table__background: $white !default;
$table__font-size: $font-size-large !default;
$table__line-height: $font-line-height !default;
$table__border-radius: $border-radius !default;
$table__border: none !default;
$table__th-background: $gray-lightest !default;
$table__td-background: $white !default;
$table__td-font-family: $font-family-sans-serif;
$table__td-font-size: $font-size-base;
$table__tfoot-background: $gray-lightest !default;
$table__background-odd--odd-even: $white !default;
$table__background-even--odd-even: #f9f9f9 !default;
$table__th-font-weight--clean: $font-weight-bold !default;
$table__th-background--clean: transparent !default;
$table__background-odd--clean: #f9f9f9 !default;
$table__background-even--clean: $white !default;
$table__font-family: $font-family-alt;
$table__th-font-weight: $font-weight-normal;

.table {
    width: 100%;
    background: $table__background;
    margin: 1em 0;
    border: $table__border;
    border-radius: $table__border-radius;
    color: $table__color;
    border-collapse: separate;
    border-spacing: 0;
    font-size: $table__font-size;
    line-height: $table__line-height;
    font-family: $table__font-family;

    & &--grouped-header {
        th {
            background: $table__th-background;
        }
    }


    &--odd-even {
        tbody {
            tr {

                &:nth-child(odd) {
                    background: $table__background-odd--odd-even;
                }

                &:nth-child(even) {
                    background: $table__background-even--odd-even;
                }

                &:first-child {
                    border-top-left-radius: $table__border-radius;
                    border-top-right-radius: $table__border-radius;

                    @include mq($screen-m) {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }

            td {
                background-color: transparent;
                // white-space: nowrap;
            }
        }
    }

    &--clean {
        margin: 0;
        border: 0;
        border-radius: 0;

        thead {
            tr {
                padding: 0;
                border-bottom: 0;
            }
        }

        th {
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
            text-align: left;
        }

        td {
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
            font-family: $table__td-font-family;
            font-size: $table__td-font-size;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--clean;
                }

                &:nth-child(even) {
                    background: $table__background-even--clean;
                }
            }
        }
    }

    &--scope-row {
        border-width: 1px 0;

        tr {
            padding: 0;

            &:nth-child(even) {
                th[scope="row"],
                td {
                    background-color: $table__background-even--odd-even;
                }
            }
        }
    }

    &__caption,
    &__visually-hidden {
        @include visually-hidden();
    }

    thead {
        tr {
            padding: 0;
            border-bottom: 0;

            @include mq($screen-m) {
                border-top-left-radius: $table__border-radius;
                border-top-right-radius: $table__border-radius;
            }
        }

        th {
            text-align: left;
            text-transform: uppercase;
            font-weight: $table__th-font-weight;
            // white-space: nowrap;

            &:first-child {
                @include mq($screen-m) {
                    border-top-left-radius: $table__border-radius;
                }
            }

            &:last-child {
                @include mq($screen-m) {
                    border-top-right-radius: $table__border-radius;
                }
            }
        }
    }

    tr {
        // display: block;
        padding: $table__padding 0;
        border-bottom: $table__border;

        @include mq($screen-m) {
            padding: 0;
            border-bottom: 0;
            // display: table-row;
        }
    }

    th {
        // display: none;
        padding: $table__padding $table__padding $table__padding $table__padding/2;

        &:last-child {
            @include mq($screen-m) {
                border-right: 0;
            }
        }

        @include mq($screen-m) {
            padding: $table__padding;
            // display: table-cell;
            background: $table__th-background--clean;
            border-bottom: $table__border;
            border-right: $table__border;
        }
    }

    td {
        // display: flex;
        padding: 2px $table__padding 2px 0;
        background: $table__td-background;
        border: none;

        // &:before {
        //     content: attr(data-th);
        //     font-weight: normal;
        //     margin-right: 10px;
        //     // max-width: 25%;
        //     width: 50%;
        //     display: block;

        //     @include mq($screen-m) {
        //         display: none;
        //     }
        // }

        &:last-child {
            padding-right: 0;

            @include mq($screen-m) {
                border-right: 0;
            }
        }

        @include mq($screen-m) {
            // display: table-cell;
            padding: $table__padding;
            background: $table__td-background;
        }
    }

    tbody {
        tr {
            &:last-child {
                td {
                    &:first-child {
                        @include mq($screen-m) {
                            border-bottom-left-radius: $table__border-radius;
                        }
                    }

                    &:last-child {
                        @include mq($screen-m) {
                            border-bottom-right-radius: $table__border-radius;
                        }
                    }
                }
            }
        }
    }

    tfoot {
        tr {
            &:last-child {
                border-bottom: 0;

                td {
                    border-bottom: 0;

                    &:first-child {
                        @include mq($screen-m) {
                            border-bottom-left-radius: $table__border-radius;
                        }
                    }

                    &:last-child {
                        @include mq($screen-m) {
                            border-bottom-right-radius: $table__border-radius;
                        }
                    }
                }
            }
        }

        td {
            display: block;
            padding: 5px $table__padding;
            background: $table__background;

            @include mq($screen-m) {
                display: table-cell;
                padding: $table__padding;
                background: $table__tfoot-background;
                border-right: $table__border;
            }
        }
    }

    &__download {
        font-size: inherit;
        font-family: inherit;
    }

    .price {
        display: table-cell;
        flex-wrap: nowrap;
    }
}

.table-wrapper.grouped {
    overflow-x: auto;
    margin: $spacer--medium 0;
}
