$cart-summary__padding                   : $spacer--medium !default;
$cart-summary__margin                    : $spacer--medium !default;
$cart-summary__background                : $gray-lightest !default;
$cart-summary__shipping-methods-margin   : 25px 0 !default;
$cart-summary__shipping-methods-font-size: $font-size-small !default;
$cart-summary__multiple-addresses-margin : $cart-summary__margin 0 ($cart-summary__margin - 5) !default;

.cart-summary {
    padding: $cart-summary__padding;
    background-color: $cart-summary__background;
    // "Arinkinkink" -C. Ho, 19__ - 2020 - 20__

    &__item {
        display: block;
        flex: 1 0 100%;

        h2 {
            line-height: 1;
        }
    }

    &__shipping-methods {
        margin: $cart-summary__shipping-methods-margin;

        &-heading {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: $cart-summary__shipping-methods-font-size;
        }
    }

    &__checkout-button {
        display: block;
        margin-bottom: 0;
    }

    &__multiple-addresses {
        display: block;
        width: 100%;
        margin: $cart-summary__multiple-addresses-margin;
        text-align: center;
    }
}
