$cart-actions__margin : 15px !default;
$cart-actions__padding: $spacer--medium 0 0 0 !default;

.cart-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $cart-actions__padding;

    .button {
        width: 100%;
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            width: auto;
        }
    }
}
