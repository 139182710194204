$dropdown-list__width: 100% !default;
$dropdown-list__bg-color: $white !default;
$dropdown-list__border-radius: 0 !default;
$dropdown-list__outline: none !default;
$dropdown-list__font-size: $font-size-base !default;
$dropdown-list__transition-height: height 0.3s !default;
$dropdown-list__transition: $transition-base !default;

// List item
$dropdown-list__item-padding: $spacer--medium !default;
$dropdown-list__item-padding-left: $spacer;
$dropdown-list__item-color: $font-color-base !default;
$dropdown-list__item-color--open: $white !default;
$dropdown-list__item-color-hover: $color-primary !default;
$dropdown-list__item-bg-color: $white !default;
$dropdown-list__item-bg-color--open: $gray-darker !default;
$dropdown-list__item-bg-color-hover: $gray-darker !default;
$dropdown-list__item-font-weight: $font-weight-base !default;
$dropdown-list__item-font-family: $font-family-heading;

// List icon
$dropdown-list__icon-width: 16px !default;
$dropdown-list__icon-height: 16px !default;
$dropdown-list__icon-fill: $black !default;
$dropdown-list__icon-fill-hover: $color-primary !default;
$dropdown-list__icon-fill--open: $white !default;

// Secondary list variant
$dropdown-list__font-weight--secondary: $font-weight-bold !default;
$dropdown-list__bg-color--secondary: $dropdown-list__bg-color !default;
$dropdown-list__item-color--secondary: $dropdown-list__item-color !default;
$dropdown-list__item-color--secondary-open: $color-primary !default;
$dropdown-list__icon-size---secondary: 24px !default;
$dropdown-list__icon-padding--secondary: 0 5px !default;
$dropdown-list__icon-margin--secondary: 0 0 0 5px !default;
$dropdown-list__icon-rotate--open: rotate(180deg) !default;
$dropdown-list__icon-fill--secondary: $dropdown-list__icon-fill !default;
$dropdown-list__icon-fill-hover--secondary: $gray !default;
$dropdown-list__icon-fill---secondary-open: $color-primary !default;

// Dark list variant
$dropdown-list__bg-color--dark: $black !default;
$dropdown-list__list-padding--dark: $spacer--medium !default;
$dropdown-list__item-border--dark: 2px solid $gray-darkest !default;
$dropdown-list__icon-fill--dark: $white !default;
$dropdown-list__icon-fill-hover--dark: $color-primary !default;
$dropdown-list__item-color--dark: $white !default;
$dropdown-list__item-color-hover--dark: $color-primary !default;
$dropdown-list__item-bg-color-hover--dark: $gray-darker !default;

// Inner list variables
$dropdown-list__item-bg-color--inner: $gray-dark !default;
$dropdown-list__item-color--inner: $white !default;
$dropdown-list__item-color-hover--inner: $color-primary !default;
$dropdown-list__icon-fill--inner: $white !default;
$dropdown-list__item-border--inner: 1px solid $white !default;
$dropdown-list__item-border--inner-level2: 1px solid $gray-dark !default;
$dropdown-list__item-font-weight--inner: $font-weight-base !default;

// screen-m list variables
$dropdown-list__content-margin--screen-m: $spacer $spacer !default;

.dropdown-list {
    width: $dropdown-list__width;
    position: relative;
    background-color: $dropdown-list__bg-color;

    &--secondary {
        background-color: $dropdown-list__bg-color--secondary;

        .dropdown-list__label, .dropdown-list__inner-link, .dropdown-list__inner-label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            text-transform: uppercase;
            font-size: $dropdown-list__font-size;
            font-weight: $dropdown-list__font-weight--secondary;
            background-color: $dropdown-list__bg-color--secondary;
            color: $dropdown-list__item-color--secondary;

            .dropdown-list__icon {
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: $dropdown-list__icon-size---secondary;
                height: $dropdown-list__icon-size---secondary;
                padding: $dropdown-list__icon-padding--secondary;
                margin: $dropdown-list__icon-margin--secondary;
                backface-visibility: hidden;
                fill: $dropdown-list__icon-fill--secondary;
            }

            &:hover,
            &:focus {
                color: $dropdown-list__item-color--secondary;
                & > .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill-hover--secondary;
                }
            }
            &[aria-expanded="true"] {
                & > .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill---secondary-open;
                }
            }
        }
    }

    &--dark {
        background-color: $dropdown-list__bg-color--dark;

        @include mq($screen-m) {
            padding: 0 $dropdown-list__list-padding--dark;
        }

        .dropdown-list__item {
            border-bottom: $dropdown-list__item-border--dark;
        }

        .dropdown-list__label,
        .dropdown-list__inner-link,
        .dropdown-list__inner-label {
            background-color: $dropdown-list__bg-color--dark;
            color: $dropdown-list__item-color--dark;
            font-weight: $font-weight-bold;

            &:hover,
            &:focus {
                color: $dropdown-list__item-color-hover--dark;
                // background-color: $dropdown-list__item-bg-color-hover--dark;

                & .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill-hover--dark;
                }
            }

            & .dropdown-list__icon {
                fill: $dropdown-list__icon-fill--dark;
            }
        }

        .dropdown-list__content {
            background-color: $dropdown-list__bg-color--dark;
        }
    }

    &--inner {
        .dropdown-list__item {
            border-bottom: $dropdown-list__item-border--inner;
        }
        .dropdown-list__label,
        .dropdown-list__inner-link,
        .dropdown-list__inner-label {
            color: $dropdown-list__item-color--inner;
            background-color: $dropdown-list__item-bg-color--inner;
            font-weight: $dropdown-list__item-font-weight--inner;
            &:hover,
            &:focus {
                color: $dropdown-list__item-color-hover--inner;
            }
            &[aria-expanded="true"] {
                border-bottom: none;
            }
            & > .dropdown-list__icon {
                fill: $dropdown-list__icon-fill--inner;
            }
        }
        .dropdown-list--inner {
            .dropdown-list__item {
                border-bottom: $dropdown-list__item-border--inner-level2;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &__list {
        display: block;
        width: $dropdown-list__width;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        width: $dropdown-list__icon-width;
        height: $dropdown-list__icon-height;
        margin: auto;
        fill: $dropdown-list__icon-fill;
        transition: $dropdown-list__transition;
    }

    &__item {
        flex: 1;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        font-size: $dropdown-list__font-size;

        &--collapse {
            .dropdown-list__label,
            .dropdown-list__inner-link,
            .dropdown-list__inner-label {
                padding-right: 30px;
            }
        }
    }

    &__label, &__inner-link, &__inner-label {
        display: block;
        position: relative;
        width: 100%;
        background-color: $dropdown-list__item-bg-color;
        padding: $dropdown-list__item-padding;
        margin: 0;
        border: 0;
        border-radius: $dropdown-list__border-radius;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        transition: $dropdown-list__transition;
        color: $dropdown-list__item-color;
        font-weight: $dropdown-list__item-font-weight;
        text-transform: uppercase;
        font-family: $dropdown-list__item-font-family;
        padding-left: $dropdown-list__item-padding-left;

        &:hover,
        &:focus {
            color: $dropdown-list__item-color-hover;
            outline: $dropdown-list__outline;

            & > .dropdown-list__icon {
                fill: $dropdown-list__icon-fill-hover;
            }
        }

        &[aria-expanded="true"] {
            color: $dropdown-list__item-color--open;
            // background-color: $dropdown-list__item-bg-color--open;

            & > .dropdown-list__icon {
                fill: $dropdown-list__icon-fill--open;
                transform: $dropdown-list__icon-rotate--open;
            }
        }

        .dropdown-list--inner .dropdown-list--inner & {
            padding-left: $dropdown-list__item-padding * 2;
        }
        .dropdown-list--inner .dropdown-list--inner .dropdown-list--inner & {
            padding-left: $dropdown-list__item-padding * 3;
        }
    }

    &__content {
        overflow: hidden;
        transition: $dropdown-list__transition-height;
        &[aria-hidden="true"] {
            display: none;
        }
    }

    @include mq($screen-m) {
        // from $screen-m drpdown list displays inline and with opened list
        &--is-open\@screen-m {
            .dropdown-list__list {
                display: flex;
                flex-flow: row nowrap;
            }

            .dropdown-list__item {
                width: 25%;
                cursor: default;
            }

            .dropdown-list__label,
            .dropdown-list__inner-link,
            .dropdown-list__inner-label {
                cursor: default;
                &:hover,
                &:focus {
                    color: $dropdown-list__item-color;
                    // background-color: $dropdown-list__item-bg-color;
                    text-decoration: none;
                }
            }
            .dropdown-list__icon {
                display: none;
            }
            .dropdown-list__content {
                margin: $dropdown-list__content-margin--screen-m;
                height: auto;
            }

            &.dropdown-list--dark {
                .dropdown-list__label,
                .dropdown-list__inner-link,
                .dropdown-list__inner-label {
                    &:hover,
                    &:focus {
                        color: $dropdown-list__item-color--dark;
                        // background-color: $dropdown-list__item-bg-color-hover--dark;
                    }
                }
            }
        }
    }
}
