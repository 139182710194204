@mixin focus($mode: "") {
    z-index: 1;
}

@mixin focus-inline($mode: "") {
    outline: none;
    text-decoration: $text-decoration-focus-inline;
    @if $mode == "dark" {
        color: $color-focus-inline-dark;
        background-color: $bg-focus-inline-dark;
    } @else {
        color: $color-focus-inline;
        background-color: $bg-focus-inline;
    }
}

@mixin focus-input($mode: "") {
    outline: none;
    @if $mode == "dark" {
        box-shadow: none;
        border: 2px solid $color-primary;
    } @else {
        border-color: $form-elements-border-color-focus;
        box-shadow: $form-input-box-shadow-focus;
    }
}
