$toolbar__border: 1px solid $gray-light !default;
$toolbar__dropdown-icon-area-border: none !default;
$toolbar__dropdown-icon-area-size: 32px !default;
$toolbar__dropdown-icon-color: $gray !default;
$toolbar__dropdown-icon-size: 12px !default;
$toolbar__dropdown-icon-area-margin: 0 !default;
$toolbar__dropdown-icon-area-right: 0 !default;
$toolbar__dropdown-icon-area-top: 0 !default;
$toolbar__offset: $spacer !default;
$toolbar__secondary-text-color: $gray !default;
$toolbar__icon-color: $gray-light !default;
$toolbar__icon-color--active: $black !default;
$toolbar__icon-color--hover: $color-primary !default;
$toolbar__mode-icon-transition: $transition-base !default;
$toolbar__mode-icon-area-size: 48px !default;
$toolbar__mode-icon-margin: 0 auto !default;
$toolbar__mode-icon-padding: 0 !default;
$toolbar__mode-icon-border: none !default;
$toolbar__mode-icon-background: none !default;
$toolbar__mode-icon-cursor: pointer !default;
$toolbar__mode-icon-size: 18px !default;
$toolbar__mode-margin-right: $spacer !default;
$toolbar__select-border: 1px solid $gray-light !default;
$toolbar__select-border-radius: 4px 4px 0 0 !default;
$toolbar__select-padding-right: $spacer--medium !default;
$toolbar__select-field-item-color: $gray-darker !default;
$toolbar__select-field-list-height: 32px !default;
$toolbar__select-field-list-z-index: 4 !default;
$toolbar__select-field-item-height: 30px !default;
$toolbar__sorter-border-top: 1px solid $gray-light !default;
$toolbar__sorter-padding: 8px 0 !default;
$toolbar__sorter-width: 200px !default;
$toolbar__sorter-icon-wrapper-cursor: pointer !default;
$toolbar__sorter-icon-size: 18px !default;
$toolbar__sorter-icon-margin: $spacer !default;
$toolbar__sorter-icon-fill: $gray !default;
$toolbar__limiter-width: 72px !default;
$toolbar__limiter-margin\@medium: $spacer 0 !default;
$toolbar__select-margin: 0 !default;
$toolbar__select-height: 32px !default;
$toolbar__select-font-family: $font-family-base !default;
$toolbar__select-single-border-radius: $spacer--medium $spacer--medium 0 0 !default;
$toolbar__select-dropdown-border-radius: 4px !default;

.toolbar {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: $toolbar__border;
    padding-bottom: $spacer--medium;

    @include mq($screen-m) {
        align-items: center;
        justify-content: flex-end;
    }

    &__mode,
    &__limiter,
    &__amount,
    &__sorter {
        flex: 0 0 auto;
        align-items: center;
    }

    &__pager {
        width: 100%;
    }

    &__amount {
        display: none;
        @include mq($screen-m) {
            flex: 1;
            justify-content: center;
            display: flex;
        }

        &--visible {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: flex-start;
            padding: $spacer 0;

            @include mq($screen-m) {
                flex: 1 0 0;
            }

            @include isIE() {
                flex-grow: 0;

                @include mq($screen-m) {
                    flex-grow: 1;
                }
            }
        }
    }

    &__amount-content {
        color: $toolbar__secondary-text-color;
    }

    &__sorter {
        border-top: $toolbar__sorter-border-top;
        padding: $toolbar__sorter-padding;
        width: 100%;
        @include mq($screen-m) {
            width: auto;
            border-top: none;
            padding: 0;
        }
    }

    &__mode {
        display: flex;
        flex: 0 0 auto;
        margin-right: $toolbar__mode-margin-right;
    }

    &__mode-icon {
        display: block;
        width: $toolbar__mode-icon-size;
        height: $toolbar__mode-icon-size;
        padding: $toolbar__mode-icon-padding;
        margin: $toolbar__mode-icon-margin;
        fill: $toolbar__icon-color;
        transition: $toolbar__mode-icon-transition;
        &--active {
            fill: $toolbar__icon-color--active;
        }
    }

    &__mode-button {
        height: $toolbar__mode-icon-area-size;
        width: $toolbar__mode-icon-area-size;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $toolbar__mode-icon-background;
        border: $toolbar__mode-icon-border;
        cursor: $toolbar__mode-icon-cursor;

        &:hover,
        &:focus {
            .toolbar__mode-icon {
                fill: $toolbar__icon-color--hover;
            }
        }
    }

    &__sorter-wrapper,
    &__limiter-wrapper {
        margin: $toolbar__select-margin;
        display: flex;
        align-items: center;
        .choices {
            flex-grow: 1;
            @include mq($screen-m) {
                width: $toolbar__sorter-width;
            }

            &.is-open {
                .select__field {
                    .select__field-list.select__field-list--single {
                        border-radius: $toolbar__select-border-radius;
                    }
                }
            }
        }
        .select__field-list {
            z-index: $toolbar__select-field-list-z-index;
            &--single {
                height: $toolbar__select-field-list-height;
                border: $toolbar__select-border;
                border-radius: $toolbar__select-border-radius;
                padding-right: $toolbar__select-padding-right;

                .choices__item--selectable {
                    height: $toolbar__select-field-list-height;
                    line-height: $toolbar__select-field-list-height;
                }
            }
        }
    }

    &__limiter-wrapper {
        .choices {
            @include mq($screen-m) {
                width: $toolbar__limiter-width;
            }
        }
    }

    .choices__list--dropdown
        .choices__item--selectable.is-highlighted
        .select__field-item {
        color: $toolbar__select-field-item-color;
        height: $toolbar__select-field-item-height;
        line-height: $toolbar__select-field-item-height;
    }

    &__sorter-select,
    &__limiter-select {
        height: $toolbar__select-height;
        font-family: $toolbar__select-font-family;
        @include mq($screen_m) {
            width: $toolbar__sorter-width;
        }
    }

    &__limiter-select {
        @include mq($screen_m) {
            width: $toolbar__limiter-width;
        }
    }

    &__sorter-label,
    &__limiter-label {
        color: $toolbar__secondary-text-color;
        margin-bottom: 0;
        margin-right: $toolbar__offset;
        white-space: nowrap;
    }

    &__sorter-label {
        display: flex;
        align-items: center;
    }

    &__sorter-icon-wrapper {
        display: flex;
        align-items: center;
        cursor: $toolbar__sorter-icon-wrapper-cursor;
    }

    &__sorter-icon {
        width: $toolbar__sorter-icon-size;
        height: $toolbar__sorter-icon-size;
        margin: $toolbar__sorter-icon-margin;
        fill: $toolbar__sorter-icon-fill;
    }

    &__limiter {
        position: absolute;
        right: 0;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20%;
        @include mq($screen-m) {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
            margin: $toolbar__limiter-margin\@medium;
        }

        &--relative {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: flex-end;
            padding: $spacer 0;
        }
    }

    &__limiter-suffix {
        display: none;
        @include mq($screen-m) {
            color: $toolbar__secondary-text-color;
            display: block;
        }
    }
    .choices {
        &.is-open {
            .select__field-list--single {
                border-radius: $toolbar__select-single-border-radius;
            }
            .choices__list--dropdown {
                border-radius: $toolbar__select-dropdown-border-radius;
            }
        }
        &.is-open:after,
        &:after {
            top: $toolbar__dropdown-icon-area-top;
            right: $toolbar__dropdown-icon-area-right;
            width: $toolbar__dropdown-icon-area-size;
            height: $toolbar__dropdown-icon-area-size;
            margin: $toolbar__dropdown-icon-area-margin;
            border: $toolbar__dropdown-icon-area-border;
            background-image: svg-uri(
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path d="M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6z" fill="#{$toolbar__dropdown-icon-color}" /></svg>'
            );
            background-size: $toolbar__dropdown-icon-size;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__item {
            &--selectable {
                &.is-highlighted {
                    background-color: #f5f5f5;
                }
            }
        }
    }

    .pager {
        display: none;
    }

    ~ .toolbar {
        border-bottom: 0;
        .pager {
            display: block;
        }
        .toolbar {
            &__mode,
            &__sorter,
            &__amount,
            &__limiter {
                display: none;
            }
        }
    }
}
