$stock-indicator__size: 10px;
$stock-indicator__color: (
    "in-stock": (
        "circle": $green,
        "border": darken($green, 10%),
    ),
    "limited-stock": (
        "circle": $orange,
        "border": darken($orange, 10%),
    ),
    "out-of-stock": (
        "circle": $red,
        "border": darken($red, 10%),
    ),
);

.stock-indicator {
    display: inline-block;
    height: $stock-indicator__size;
    width: $stock-indicator__size;
    margin-right: $spacer;
    border-radius: 50%;
    border: 2px solid;

    &.-in-stock {
        background-color: map-get(
            map-get($stock-indicator__color, "in-stock"),
            "circle"
        );
        border-color: map-get(
            map-get($stock-indicator__color, "in-stock"),
            "border"
        );
    }

    &.-limited-stock {
        background-color: map-get(
            map-get($stock-indicator__color, "limited-stock"),
            "circle"
        );
        border-color: map-get(
            map-get($stock-indicator__color, "limited-stock"),
            "border"
        );
    }

    &.-out-of-stock {
        background-color: map-get(
            map-get($stock-indicator__color, "out-of-stock"),
            "circle"
        );
        border-color: map-get(
            map-get($stock-indicator__color, "out-of-stock"),
            "border"
        );
    }
}
