$filters__heading-font-weight        : $font-weight-bold !default;
$filters__heading-font-weight\@medium: $font-weight-normal !default;
$filters__heading-margin             : 0 !default;
$filters__heading-margin\@medium     : 0 0 $spacer--medium 0 !default;
$filters__skip-button-height         : $spacer--extra-large !default;
$filters__skip-button-padding        : 0 $spacer--medium !default;
$filters__skip-button-margin-bottom  : $spacer !default;
$filters__switcher-margin            : $spacer 0 0 0 !default;
$filters__switcher-padding           : 0 !default;
$filters__switcher-border            : 1px solid $gray-lighter !default;
$filters__switcher-border-radius     : 0 !default;
$filters__switcher-background-color  : $bg-color-base !default;
$filters__switcher-color             : $color-secondary !default;
$filters__switcher-font-size         : $font-size-large !default;
$filters__switcher-font-family       : $font-family-base !default;
$filters__switcher-transition        : $transition-base !default;
$filters__switcher-fill              : $color-primary !default;
$filters__switcher-size              : $spacer--extra-large !default;
$filters__switcher-icon-margin       : $spacer !default;
$filters__switcher-icon-padding      : 0 5px !default;
$filters__list-margin                : 0 0 $spacer--medium 0 !default;
$filters__divider-margin             : 0 0 $spacer--medium 0 !default;
$filters__active-margin              : 0 0 $spacer--medium 0 !default;

.filters {
    &__heading {
        font-weight: $filters__heading-font-weight;
        margin: $filters__heading-margin;
        @include mq($screen-l) {
            font-weight: $filters__heading-font-weight\@medium;
            margin: $filters__heading-margin\@medium;
        }
        &--mobile-hidden {
            display: none;
            @include mq($screen-l) {
                display: block;
            }
        }
    }

    &__skip-button {
        @include visually-hidden($usePseudo: true);
        transition: none;

        &:focus {
            height: $filters__skip-button-height;
            padding: $filters__skip-button-padding;
            margin-bottom: $filters__skip-button-margin-bottom;
        }
    }

    &__switcher {
        display: flex;
        align-items: center;
        height: $filters__switcher-size;
        min-width: 100%;
        margin: $filters__switcher-margin;
        padding: $filters__switcher-padding;
        border: none;
        border-top: $filters__switcher-border;
        border-radius: $filters__switcher-border-radius;
        background-color: $filters__switcher-background-color;
        color: $filters__switcher-color;
        font-size: $filters__switcher-font-size;
        font-family: $filters__switcher-font-family;
        text-decoration: none;
        text-align: left;

        &:hover,
        &:focus {
            color: $filters__switcher-color;
            text-decoration: none;
            cursor: pointer;
        }

        @include mq($screen-l) {
            display: none;
        }
    }

    &__switcher-icon {
        margin-left: $filters__switcher-icon-margin;
        padding: $filters__switcher-icon-padding;
        transition: $filters__switcher-transition;

        &--active {
            fill: $filters__switcher-fill;
            transform: rotate(180deg);
        }
    }

    &__list {
        margin: $filters__list-margin;
        &--mobile-hidden {
            display: none;
            @include mq($screen-l) {
                display: block;
            }
        }
    }

    &__active {
        margin: $filters__active-margin;
    }

    &__divider {
        display: none;
        margin: $filters__divider-margin;
        @include mq($screen-l) {
            display: flex;
        }
    }
}
